function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import authActionTypes from '../actions/authActionTypes';
var initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isResettingPassword: false,
  isChangingPassword: false,
  isInitialising: false,
  isMagicFailed: false
};
export default function auth() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case authActionTypes.LOGIN_USER_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAuthenticating: true
      });
    case authActionTypes.LOGIN_USER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAuthenticating: false,
        isAuthenticated: true
      });
    case authActionTypes.LOGIN_USER_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAuthenticating: false,
        isAuthenticated: false
      });
    case authActionTypes.LOGOUT_USER:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAuthenticated: false
      });
    case authActionTypes.RESET_PASSWORD_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isResettingPassword: true
      });
    case authActionTypes.RESET_PASSWORD_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isResettingPassword: false
      });
    case authActionTypes.RESET_PASSWORD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isResettingPassword: false
      });
    case authActionTypes.CHANGE_PASSWORD_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isChangingPassword: true
      });
    case authActionTypes.CHANGE_PASSWORD_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isChangingPassword: false
      });
    case authActionTypes.CHANGE_PASSWORD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isChangingPassword: false
      });
    case authActionTypes.AUTH_INITIALISATION_START:
      return _objectSpread(_objectSpread({}, state), {}, {
        isInitialising: true
      });
    case authActionTypes.AUTH_INITIALISATION_END:
      return _objectSpread(_objectSpread({}, state), {}, {
        isInitialising: false
      });
    case authActionTypes.MAGIC_USER_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isMagicFailed: true
      });
    case authActionTypes.MAGIC_USER_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isMagicFailed: false
      });
    default:
      return state;
  }
}