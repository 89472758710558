var OPEN_CURLY_BRACE = 0x7B;
export default function rule(OPENING_TAG_REGEX, CLOSING_TAG_REGEX, TOKEN_TYPE, MARKUP, STYLE_TAG_TYPE) {
  return function (state, silentMode) {
    var result;
    var nesting = 1;
    var max = state.posMax;
    var start = state.pos;
    if (state.src.charCodeAt(start) !== OPEN_CURLY_BRACE) {
      return false;
    }
    if (silentMode) {
      return false;
    }
    var content = state.src.slice(start);
    var openingTag = OPENING_TAG_REGEX.exec(content);
    var closingTag = CLOSING_TAG_REGEX.exec(content);
    if (!openingTag && !closingTag) {
      return false;
    }
    if (openingTag && closingTag) {
      if (openingTag.index < closingTag.index) {
        result = openingTag;
      } else {
        nesting = -1;
        result = closingTag;
      }
    } else if (openingTag) {
      result = openingTag;
    } else {
      result = closingTag;
      nesting = -1;

      // eslint-disable-next-line no-param-reassign
      state.pos = start + result[0].length;
      // eslint-disable-next-line no-param-reassign
      state.posMax = max;
      if (state.pendingLevel > 0) {
        var _token = state.push(TOKEN_TYPE, STYLE_TAG_TYPE, nesting);
        _token.markup = MARKUP;
      }
      return true;
    }
    var isOpen = result.length === 2;
    var token = state.push(TOKEN_TYPE, STYLE_TAG_TYPE, nesting);
    token.markup = MARKUP;
    if (isOpen) {
      token.attrPush([STYLE_TAG_TYPE, result[1]]);
    }

    // eslint-disable-next-line no-param-reassign
    state.pos = start + result[0].length;
    // eslint-disable-next-line no-param-reassign
    state.posMax = max;
    return true;
  };
}