import _toConsumableArray from "/Users/db/Projects/agora/agora-web/node_modules/.pnpm/@babel+runtime@7.3.1/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
export default function menuItems() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'REGISTER_MENU_ITEM':
      return [].concat(_toConsumableArray(state), [{
        icon: action.payload.icon,
        route: action.payload.route,
        label: action.payload.label
      }]);
    case 'CLEAR_MENU_ITEMS':
      return [];
    default:
      return state;
  }
}