import React from 'react';
import PropTypes from 'prop-types';
import MUISnackbar from '@material-ui/core/Snackbar';
import SnackbarContent from './SnackbarContent';

export default function Snackbar(
  { message, open, close, success, actionLabel, onActionClick, autoHideDuration }) {
  return (
    <MUISnackbar
      open={open}
      onClose={close}
      autoHideDuration={success ? autoHideDuration : null}
    >
      <SnackbarContent
        variant={success ? 'success' : 'error'}
        actionLabel={actionLabel}
        onActionClick={onActionClick}
        message={message}
      />
    </MUISnackbar>
  );
}

Snackbar.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  actionLabel: PropTypes.string,
  onActionClick: PropTypes.func.isRequired,
  autoHideDuration: PropTypes.number,
};

Snackbar.defaultProps = {
  actionLabel: null,
  autoHideDuration: 4000,
};
