import React from 'react';
import ReactDOM from 'react-dom';
import ImageViewer from '../../ImageViewer';

export default function ImageRenderer({ placeholders }) {
  if (!placeholders) {
    return null;
  }

  return placeholders.map((p) => {
    const imageUrl = p.dataset.src;
    const alt = p.dataset.alt;

    return ReactDOM.createPortal(
      <ImageViewer src={imageUrl} alt={alt}/>,
      p,
    );
  });
}
