import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingIndicator({ message }) {
  return (
    <div
      style={{ height: '100%', width: '100%', flex: 'inherit' }}
      className="row middle-xs"
    >
      <div
        className="row middle-xs center-xs"
        style={{ margin: '0 auto', position: 'relative' }}
      >
        <span>{message}</span>
        <CircularProgress color="primary" size={30} />
      </div>
    </div>
  );
}

LoadingIndicator.propTypes = {
  message: PropTypes.string,
};

LoadingIndicator.defaultProps = {
  message: '',
};
