import { connect } from 'react-redux';
import LoginPage from './LoginPage';
import { verifyLogin as _verifyLogin } from '../actions/authActions';
function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticating: state.auth.isAuthenticating
  };
}
function mapDispatchToProps(dispatch) {
  return {
    verifyLogin: function verifyLogin() {
      return dispatch(_verifyLogin(null, false));
    }
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);