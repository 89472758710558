import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MuiButton from '@material-ui/core/Button';
import {
  withStyles as withStylesMUI,
  withTheme,
} from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import lightGreen from '@material-ui/core/colors/lightGreen';
import green from '@material-ui/core/colors/green';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import Fade from '@material-ui/core/Fade';
import { hmButtonColors } from './colorPropType';
import DefaultButtonPropTypes from './DefaultButtonPropTypes';
import oneOfWithTheme from '../utils/oneOfWithTheme';

import materialNextHivemindTheme from '../styles/nextHivemindTheme';
import Themes from '../styles/Themes';

const styles = theme => {
  const oneOf = oneOfWithTheme(theme.name);
  return {
    danger: {
      backgroundColor: red[900],
      color: theme.palette.getContrastText(red[900]),
      '&:hover': {
        backgroundColor: alpha(red[900], 0.7),
      },
    },
    success: {
      backgroundColor: lightGreen.A700,
      color: theme.palette.getContrastText(lightGreen.A700),
      '&:hover': {
        backgroundColor: alpha(lightGreen.A700, 0.7),
      },
    },
    dangerTransparent: {
      color: oneOf(red.A700, red.A200),
      borderColor: red.A700,
      '&:hover': {
        backgroundColor: alpha(red.A700, 0.2),
      },
    },
    successTransparent: {
      color: oneOf(green.A700, lightGreen.A200),
      borderColor: oneOf(green.A700, lightGreen.A700),
      '&:hover': {
        backgroundColor: alpha(oneOf(green.A700, lightGreen.A700), 0.15),
      },
    },
  };
};

const muiColors = ['primary', 'secondary', 'default', 'inherit'];

const transparentBackgroundVariants = ['outlined', 'text'];

const getColorVariant = (variant, color, classes) => {
  if (transparentBackgroundVariants.includes(variant)) {
    return classes[`${color}Transparent`];
  }
  return hmButtonColors.includes(color) ? classes[color] : '';
};

function Button({
  Icon,
  color,
  variant,
  size,
  classes,
  className,
  children,
  theme,
  timeout,
  ...rest
}) {
  return (
    <Fade
      in
      mountOnEnter
      unmountOnExit
      timeout={timeout}
      onEntered={node => {
        // Because of the Fade component, the theme provider transition styles are overridden
        // on entry. This reapplies the transition from the theme provider to the button.
        // Issue being tracked here: https://github.com/mui-org/material-ui/issues/24390
        // eslint-disable-next-line no-param-reassign
        node.style.transition = `${theme.transitions.create(['background-color'])}, ${node.style.transition}`;
      }}
    >
      <MuiButton
        color={muiColors.includes(color) ? color : 'inherit'}
        variant={variant}
        className={classNames(
          getColorVariant(variant, color, classes),
          className,
        )}
        size={size}
        startIcon={Icon && <Icon />}
        {...rest}
      >
        {children}
      </MuiButton>
    </Fade>
  );
}

Button.propTypes = {
  ...DefaultButtonPropTypes,
  classes: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
  timeout: PropTypes.number,
};

Button.defaultProps = {
  timeout: 350,
  Icon: null,
  size: 'medium',
  className: '',
  color: 'primary',
  variant: 'contained',
  children: null,
};

const withStyles = s =>
  withStylesMUI(s, {
    withTheme: true,
    defaultTheme: materialNextHivemindTheme(Themes.DARK),
  });

export default withStyles(styles)(withTheme(Button));
