import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import red from '@material-ui/core/colors/red';
import lightGreen from '@material-ui/core/colors/lightGreen';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import { hmButtonColorPropType } from '../Button/colorPropType';

const styles = theme => ({
  root: {},
  success: {
    transition: theme.transitions.create(['color']),
    padding: 12,
    color: lightGreen.A700,
    '&:hover': {
      backgroundColor: alpha(lightGreen.A700, 0.08),
    },
  },
  danger: {
    transition: theme.transitions.create(['color']),
    padding: 12,
    color: red[900],
    '&:hover': {
      backgroundColor: alpha(red[900], 0.08),
    },
  },
  icon: {},
});

const getColor = (color, classes) => classes[color];
const colors = ['success', 'danger'];

const IconButton = React.forwardRef(({
  Icon,
  size,
  classes,
  color,
  accessibilityLabel,
  ...rest
}, ref) => {
  const iconColor = colors.includes(color) ? 'default' : color;
  const root = colors.includes(color) ? getColor(color, classes) : classes.root;
  return (
    <MuiIconButton
      classes={{ root }}
      ref={ref}
      color={iconColor}
      size={size}
      aria-label={accessibilityLabel}
      {...rest}
    >
      <Icon classes={{ root: classes.icon }} fontSize="inherit" />
    </MuiIconButton>
  );
});

IconButton.propTypes = {
  accessibilityLabel: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  classes: PropTypes.shape({}).isRequired,
  color: hmButtonColorPropType,
};

IconButton.defaultProps = {
  size: 'small',
  color: 'default',
};

export default withStyles(styles, { withTheme: true })(IconButton);

