function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import authFetch from './authFetch';
var controllers = new Map();
function abort(callerId) {
  var controller = controllers.get(callerId);
  if (controller) {
    controller.abort();
  }
}
function createAbortSignal(callerId) {
  var abortFetchSignal;
  if ('AbortController' in window) {
    controllers.set(callerId, new AbortController());
    abortFetchSignal = controllers.get(callerId).signal;
  }
  return abortFetchSignal;
}
function disposeController(callerId) {
  controllers.delete(callerId);
}
function isError(result) {
  return result instanceof Error;
}
function isAbortError(error) {
  return error && error.name === 'AbortError';
}

/**
 * Calls the given url using authFetch. In addition, if you make another call with the
 * same callerId before this call has returned it will cancel the http request.
  * @param {string} url
 * @param {object} options - fetch options
 * @param {string} callerId - a unique identity used to cancel the call on subsequent requests.
 *  When omitted it falls back to using the url.
 * @param {boolean} returnLinkHeaders
 */
export default function cancellableAuthFetch(url) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _ref$fetchOptions = _ref.fetchOptions,
    fetchOptions = _ref$fetchOptions === void 0 ? {} : _ref$fetchOptions,
    _ref$callerId = _ref.callerId,
    callerId = _ref$callerId === void 0 ? url : _ref$callerId,
    _ref$returnLinkHeader = _ref.returnLinkHeaders,
    returnLinkHeaders = _ref$returnLinkHeader === void 0 ? false : _ref$returnLinkHeader,
    _ref$returnRaw = _ref.returnRaw,
    returnRaw = _ref$returnRaw === void 0 ? false : _ref$returnRaw;
  if (typeof callerId !== 'string') {
    throw new Error('callerId must be a string.');
  }
  abort(callerId);
  var signal = createAbortSignal(callerId);
  var abortListenerHandler = function abortListenerHandler() {
    return disposeController(callerId);
  };
  signal.addEventListener('abort', abortListenerHandler, {
    once: true
  });
  return authFetch(url, _objectSpread(_objectSpread({}, fetchOptions), {}, {
    signal: signal
  }), {
    returnLinkHeaders: returnLinkHeaders,
    returnRaw: returnRaw
  }).catch(function (error) {
    return error;
  }).then(function (result) {
    signal.removeEventListener('abort', abortListenerHandler);
    if (!isError(result) || !isAbortError(result)) {
      disposeController(callerId);
    }
    if (isError(result)) {
      throw result;
    }
    return result;
  });
}

/**
 * Helper function to ignore AbortErrors (on cancel).
 * Usage: `.catch(handleCancellationError(error => customErrorHandling(error)))`
 * @param {function} onError - callback function to be called on non-abort error. The call to
 * this function is passed the error from the fetch failure.
 */
export function handleCancellationError(onError) {
  return function (error) {
    if (error.name !== 'AbortError') {
      return onError(error);
    }
    return Promise.resolve(null);
  };
}