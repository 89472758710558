import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import LoadingPanel from '../LoadingPanel';
import themes from '../../styles/Themes';
import moduleTypes from './moduleLoadingTypes';

export default function ModuleLoadingBase(
  { pastDelay, error, retry, timedOut, minHeight, type, theme, setBackgroundColor }) {
  let backgroundColor;

  if (setBackgroundColor) {
    backgroundColor = theme === themes.LIGHT ? 'white' : '#333f48';
  }

  const wrapperStyle = { backgroundColor, height: minHeight };

  if (error || timedOut) {
    let message;
    if (error) {
      message = `An error has occurred loading the ${type}. Try again?`;
    } else if (timedOut) {
      message = `It's taking a long time to load the ${type}. Try again?`;
    }
    return (
      <div style={{ ...wrapperStyle, minHeight, flex: 'inherit' }} className="row middle-xs">
        <div className="row middle-xs center-xs" style={{ margin: '0 auto', position: 'relative' }}>
          <div className="col-xs-12">
            <p>{message}</p>
          </div>
          <div className="col-xs-12">
            <Button onClick={retry}>
              Retry
            </Button>
          </div>
        </div>
      </div>);
  } if (pastDelay) {
    if (type === moduleTypes.PAGE) {
      return (
        <div style={wrapperStyle}>
          <LoadingPanel isLoading />
        </div>);
    }
    return <LoadingPanel isLoading />;
  }
  return <div style={wrapperStyle} />;
}

ModuleLoadingBase.propTypes = {
  pastDelay: PropTypes.bool,
  error: PropTypes.bool,
  timedOut: PropTypes.bool,
  retry: PropTypes.func,
  minHeight: PropTypes.string,
  type: PropTypes.string.isRequired,
  theme: PropTypes.string,
  setBackgroundColor: PropTypes.bool,
};

ModuleLoadingBase.defaultProps = {
  minHeight: '100%',
  pastDelay: false,
  error: false,
  timedOut: false,
  retry: () => { },
  theme: undefined,
  setBackgroundColor: false,
};
