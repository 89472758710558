import _toConsumableArray from "/Users/db/Projects/agora/agora-web/node_modules/.pnpm/@babel+runtime@7.3.1/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { createTheme } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import grey from '@material-ui/core/colors/grey';
import { alpha, lighten } from '@material-ui/core/styles/colorManipulator';
import Themes from '../styles/Themes';
import { blue, dark as darkColour, yellow, lightBlue, white, black, darkGrey, lightGrey, mediumGrey, solidLightGrey } from './colours';
import oneOfWithTheme from '../utils/oneOfWithTheme';
var boxShadows = ['0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12)', '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)', '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)'];
export default function materialNextHivemindTheme(theme) {
  var oneOf = oneOfWithTheme(theme);
  return createTheme({
    name: theme,
    palette: {
      action: {
        selectedBorder: oneOf(lightGrey, grey[500])
      },
      type: theme === Themes.DARK || theme === Themes.DARK_BLUE ? 'dark' : 'light',
      primary: {
        main: oneOf(blue, yellow, lightBlue),
        contrastText: oneOf(black, white, white),
        contrastIcon: oneOf(darkGrey, lightGrey)
      },
      secondary: {
        main: oneOf(darkGrey, lightGrey)
      },
      background: {
        section: oneOf(solidLightGrey, darkColour, darkColour),
        paper: oneOf(lighten(blueGrey[100], 0.7), blueGrey[800]),
        default: oneOf(lighten(blueGrey[100], 0.7), black)
      }
    },
    shadows: ['none'].concat(boxShadows, _toConsumableArray(Array(21).fill('none'))),
    overrides: {
      MuiSvgIcon: {
        colorPrimary: {
          color: oneOf(blue, yellow, lightBlue)
        }
      },
      MuiButton: {
        root: {
          textTransform: 'none'
        },
        text: {
          color: oneOf(mediumGrey, mediumGrey)
        },
        textPrimary: {
          color: oneOf(blue, yellow, lightBlue)
        },
        textSecondary: {
          color: oneOf(darkGrey, mediumGrey)
        },
        outlined: {
          border: "1px solid ".concat(blueGrey[400]),
          color: blueGrey[400]
        },
        contained: {
          boxShadow: 'none',
          backgroundColor: blueGrey[400],
          color: white,
          '&:hover': {
            backgroundColor: alpha(blueGrey[400], 0.7)
          }
        },
        containedPrimary: {
          color: oneOf(white, darkGrey, darkGrey)
        }
      },
      MuiToggleButton: {
        root: {
          borderTop: 'none',
          borderBottom: 'none',
          textTransform: 'none',
          color: oneOf(black, white),
          backgroundColor: oneOf(alpha(blueGrey[100], 0.5), blueGrey[900]),
          borderLeft: "3px solid ".concat(oneOf(blueGrey[300], blueGrey[700])),
          borderRight: "3px solid ".concat(oneOf(blueGrey[300], blueGrey[700])),
          '&:hover': {
            backgroundColor: oneOf(blueGrey[200], blueGrey[800])
          },
          '&:first-child': {
            borderLeft: 'none'
          },
          '&:last-child': {
            borderRight: 'none'
          },
          '&$selected': {
            color: "".concat(white, " !important"),
            backgroundColor: "".concat(oneOf(blueGrey[300], blueGrey[700]), " !important")
          }
        }
      },
      MuiToggleButtonGroup: {
        root: {
          boxShadow: 'none',
          borderRadius: '10px',
          overflow: 'hidden',
          border: "2px solid ".concat(oneOf(blueGrey[300], blueGrey[700])),
          width: 'fit-content',
          '&$selected': {
            boxShadow: 'none'
          }
        }
      },
      MuiFab: {
        label: {
          color: oneOf(white, darkGrey)
        }
      },
      MuiAutocomplete: {
        paper: {
          backgroundColor: oneOf(blueGrey[100], blueGrey[700]),
          boxShadow: boxShadows[0]
        }
      },
      MuiMenu: {
        paper: {
          backgroundColor: oneOf(blueGrey[100], blueGrey[700]),
          boxShadow: boxShadows[0]
        }
      },
      MuiMenuItem: {
        root: {
          backgroundColor: oneOf(blueGrey[100], blueGrey[700])
        }
      }
    },
    typography: {
      fontFamily: '"Lato", sans-serif',
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 300,
      fontWeightMedium: 500,
      subtitle1: {
        color: oneOf(darkGrey, white),
        opacity: oneOf(1, 0.5),
        fontFamily: 'Josefin Sans',
        fontWeight: 600,
        fontSize: '0.75em',
        letterSpacing: '0.06316em',
        lineHeight: 1.33,
        textTransform: 'uppercase'
      }
    }
  });
}