import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { withStyles, withTheme } from '@agora/agora-common';
import TextField from '@material-ui/core/TextField';
import FormSection from '../Common/FormSection';

const styles = () => ({
  textField: {
    margin: '1em 0',
  },
});

function SocialPreferences({
  classes,
  defaultInviteToOtherMarkets,
  defaultGitHubUrl,
  defaultGoogleScholarUrl,
  defaultLinkedInUrl,
  defaultOther,
  gitHubUrlErrorText,
  googleScholarUrlErrorText,
  linkedInUrlErrorText,
  otherErrorText,
  onInviteToOtherMarketsChanged,
  onGithubUrlChanged,
  onGoogleScholarUrlChanged,
  onLinkedInUrlChanged,
  onOtherChanged,
  isSaving,
}) {
  return (
    <FormSection title="Social">
      <FormGroup>
        <FormControlLabel
          control={(
            <Checkbox
              color="secondary"
              defaultChecked={defaultInviteToOtherMarkets}
              onChange={event => onInviteToOtherMarketsChanged(event.target.checked)}
            />
)}
          label="Would you like to be invited to other AGORA markets?"
        />
      </FormGroup>
      Please provide URLs for the following if they are applicable and you would like to share them with CRUCIAL
      (they will not be visible to other participants):
      <TextField
        defaultValue={defaultGitHubUrl}
        disabled={isSaving}
        fullWidth
        label="Your GitHub profile"
        onChange={event => onGithubUrlChanged(event.target.value)}
        placeholder="e.g. https://github.com/<username>"
        error={!!gitHubUrlErrorText}
        helperText={gitHubUrlErrorText}
        classes={{ root: classes.textField }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        defaultValue={defaultGoogleScholarUrl}
        disabled={isSaving}
        fullWidth
        label="Your Google Scholar profile"
        onChange={event => onGoogleScholarUrlChanged(event.target.value)}
        placeholder="e.g.  https://scholar.google.com/citations?user=<userId>"
        error={!!googleScholarUrlErrorText}
        helperText={googleScholarUrlErrorText}
        classes={{ root: classes.textField }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        defaultValue={defaultLinkedInUrl}
        disabled={isSaving}
        fullWidth
        label="Your LinkedIn profile"
        onChange={event => onLinkedInUrlChanged(event.target.value)}
        placeholder="e.g. https://www.linkedin.com/in/<username>"
        error={!!linkedInUrlErrorText}
        helperText={linkedInUrlErrorText}
        classes={{ root: classes.textField }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      Do you have any other qualifications or interests you would like us to know about?
      <TextField
        defaultValue={defaultOther}
        disabled={isSaving}
        fullWidth
        multiline
        onChange={event => onOtherChanged(event.target.value)}
        error={!!otherErrorText}
        helperText={otherErrorText}
        classes={{ root: classes.textField }}
      />
    </FormSection>
  );
}

SocialPreferences.propTypes = {
  classes: PropTypes.object.isRequired,
  defaultInviteToOtherMarkets: PropTypes.bool,
  defaultGitHubUrl: PropTypes.string,
  defaultGoogleScholarUrl: PropTypes.string,
  defaultLinkedInUrl: PropTypes.string,
  defaultOther: PropTypes.string,
  gitHubUrlErrorText: PropTypes.string,
  googleScholarUrlErrorText: PropTypes.string,
  linkedInUrlErrorText: PropTypes.string,
  otherErrorText: PropTypes.string,
  onInviteToOtherMarketsChanged: PropTypes.func,
  onGithubUrlChanged: PropTypes.func,
  onGoogleScholarUrlChanged: PropTypes.func,
  onLinkedInUrlChanged: PropTypes.func,
  onOtherChanged: PropTypes.func,
  isSaving: PropTypes.bool,
};

SocialPreferences.defaultProps = {
  defaultInviteToOtherMarkets: false,
  defaultGitHubUrl: null,
  defaultGoogleScholarUrl: null,
  defaultLinkedInUrl: null,
  defaultOther: null,
  gitHubUrlErrorText: '',
  googleScholarUrlErrorText: '',
  linkedInUrlErrorText: '',
  otherErrorText: '',
  onInviteToOtherMarketsChanged: () => {},
  onGithubUrlChanged: () => {},
  onGoogleScholarUrlChanged: () => {},
  onLinkedInUrlChanged: () => {},
  onOtherChanged: () => {},
  isSaving: false,
};

export default withStyles(styles)(withTheme(SocialPreferences));
