import React from 'react';
import moduleLoadingTypes from '../moduleLoadingTypes';

export default function loadingComponent(WrappedModuleLoadingBase) {
  const loading =
    function LoadingComponent(props) {
      const newProps = {
        ...props,
        minHeight: '100%',
        type: moduleLoadingTypes.COMPONENT,
        setBackgroundColor: false,
      };

      return <WrappedModuleLoadingBase {...newProps} />;
    };

  return loading;
}
