import _toConsumableArray from "/Users/db/Projects/agora/agora-web/node_modules/.pnpm/@babel+runtime@7.3.1/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
var _excluded = ["actionConfig"];
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _objectWithoutProperties(e, t) {
  if (null == e) return {};
  var o,
    r,
    i = _objectWithoutPropertiesLoose(e, t);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]);
  }
  return i;
}
function _objectWithoutPropertiesLoose(r, e) {
  if (null == r) return {};
  var t = {};
  for (var n in r) if ({}.hasOwnProperty.call(r, n)) {
    if (-1 !== e.indexOf(n)) continue;
    t[n] = r[n];
  }
  return t;
}
import { connect } from 'react-redux';
import Snackbar from './Snackbar';
import { closeSnackbar } from '../actions/snackbarActions';
function mapDispatchToProps(dispatch) {
  return {
    dispatchClose: function dispatchClose() {
      dispatch(closeSnackbar());
    },
    dispatchOnActionClick: function dispatchOnActionClick(actionType, props, actionConfig) {
      if (!actionType) {
        throw Error('You must provide an action type to dispatch on click.');
      }
      var configuredAction = actionConfig[actionType];
      if (!configuredAction || configuredAction && !configuredAction.action) {
        throw Error("Next action ".concat(actionType, " is not configured correctly"));
      }
      dispatch(closeSnackbar());
      dispatch(configuredAction.action.apply(configuredAction, _toConsumableArray(props)));
    }
  };
}
function mapStateToProps(state, ownProps) {
  var actionConfig = ownProps.actionConfig ? ownProps.actionConfig : {};
  var actionType = state.ui.snackbar.actionType;
  var actionLabel = actionConfig[actionType] && actionConfig[actionType].label ? actionConfig[actionType].label : actionType;

  // material-ui Snackbars close and reopen upon the 'message' prop changing,
  // by using a 'key' prop, the element will be recreated when this value changes,
  // giving the appearance that it never closed and the 'message' just updated.
  // https://github.com/mui-org/material-ui/issues/3186

  return {
    key: state.ui.snackbar.key,
    open: state.ui.snackbar.open,
    message: state.ui.snackbar.message,
    success: state.ui.snackbar.success,
    actionLabel: actionLabel,
    actionProps: state.ui.snackbar.actionProps,
    actionType: actionType,
    actionConfig: actionConfig,
    autoHideDuration: ownProps.autoHideDuration
  };
}
function mergeProps(stateProps, dispatchProps) {
  var actionConfig = stateProps.actionConfig,
    cleanStateProps = _objectWithoutProperties(stateProps, _excluded);
  return _objectSpread({
    close: function close() {
      return dispatchProps.dispatchClose();
    },
    onActionClick: function onActionClick() {
      return dispatchProps.dispatchOnActionClick(stateProps.actionType, stateProps.actionProps, stateProps.actionConfig);
    }
  }, cleanStateProps);
}
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Snackbar);