import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import FormHolder from '../FormHolder/FormHolder';
import ForgotPasswordForm from './ForgotPasswordFormContainer';

export default function ForgotPasswordPage({ subText }) {
  const handleCancel = (e) => {
    e.preventDefault();
    browserHistory.push('/login');
  };

  return (
    <FormHolder subText={subText}>
      <div>
        <h1>Reset Password</h1>
        <ForgotPasswordForm
          onCancel={handleCancel}
        />
      </div>
    </FormHolder>
  );
}

ForgotPasswordPage.propTypes = {
  subText: PropTypes.string,
};

ForgotPasswordPage.defaultProps = {
  subText: 'PLATFORM',
};
