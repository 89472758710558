import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles'; // v1.x
import Themes from '../styles/Themes';
import materialNextHivemindTheme from '../styles/nextHivemindTheme';

export default function ThemeProvider({ theme, children }) {
  return (
    <MuiThemeProvider theme={materialNextHivemindTheme(theme)}>
      <div className={`hm-${theme}`}>{children}</div>
    </MuiThemeProvider>
  );
}

ThemeProvider.propTypes = {
  theme: PropTypes.oneOf([Themes.LIGHT, Themes.DARK, Themes.DARK_BLUE])
    .isRequired,
  children: PropTypes.element.isRequired,
};
