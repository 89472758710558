import rule from './rule';
import spanRenderer from './spanRenderer';

/**
 * This HOF creates a markdown-it plugin which can render <span>'s with
 *  a defined style tag. The syntax it uses in markdown is {type: param}
 */
export default function textStylePlugin(OPENING_TAG_REGEX, CLOSING_TAG_REGEX, TOKEN_TYPE, MARKUP, STYLE_TAG_TYPE, applyAfterRule) {
  return function (md, _, options) {
    var opts = options || {};
    var coloredTextRule = rule(OPENING_TAG_REGEX, CLOSING_TAG_REGEX, TOKEN_TYPE, MARKUP, STYLE_TAG_TYPE);
    md.inline.ruler.after(applyAfterRule, STYLE_TAG_TYPE, coloredTextRule);

    // eslint-disable-next-line no-param-reassign
    md.renderer.rules[TOKEN_TYPE] = opts.render || spanRenderer(STYLE_TAG_TYPE);
  };
}