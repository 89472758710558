import React from 'react';
import PropTypes from 'prop-types';

export default function ScreenReaderOnly({ children }) {
  return (
    <span
      style={{
        position: 'absolute',
        width: '1px',
        height: '1px',
        padding: '0',
        margin: '-1px',
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        whiteSpace: 'nowrap',
        borderWidth: '0',
      }}
    >
      {children}
    </span>
  );
}

ScreenReaderOnly.propTypes = {
  children: PropTypes.node.isRequired,
};
