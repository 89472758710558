import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Logout from '../Icons/Logout';
import MoreHorizontal from '../Icons/MoreHorizontal';
import ThemeMenu from './ThemeMenu/ThemeMenuContainer';
import StatsBottomSheetButton from './StatsBottomSheetButton/StatsBottomSheetButton';
import AppFilterMenuContainer from './AppFilters/AppFilterMenuContainer';
import ChangelogMenu from './ChangeLogMenu';
import IconMenu from '../IconMenu';

export default function SideNavFooterMenu(props) {
  const buttons = (
    <div>
      {
        props.appFilterTypes.map(type =>
          <AppFilterMenuContainer key={type} filterType={type} />)
      }
      {
        props.showChangelog && <ChangelogMenu/>
      }
      {props.showBottomSheetButton &&
        <StatsBottomSheetButton
          toggleBottomSheet={props.toggleBottomSheet}
          bottomSheetIcon={props.bottomSheetIcon}
        />
      }
      <IconMenu Icon={MoreHorizontal} accessibilityLabel="open menu">
        { props.showThemeMenu &&
        [
          <ThemeMenu key="theme-menu" />,
          <Divider key="divider" />,
        ]
        }
        <MenuItem onClick={props.logoutAndRedirect} >
          <ListItemText>
          Log out
          </ListItemText>
          <ListItemSecondaryAction>
            <Logout className="hm-side-nav-menu-icon" />
          </ListItemSecondaryAction>
        </MenuItem>
      </IconMenu>
    </div>);

  return (
    <div style={{ width: '100%', height: props.footerHeight, textAlign: 'center' }}>
      {buttons}
    </div>
  );
}

SideNavFooterMenu.propTypes = {
  logoutAndRedirect: PropTypes.func.isRequired,
  toggleBottomSheet: PropTypes.func,
  showBottomSheetButton: PropTypes.bool.isRequired,
  bottomSheetIcon: PropTypes.node,
  showThemeMenu: PropTypes.bool.isRequired,
  footerHeight: PropTypes.number.isRequired,
  appFilterTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  showChangelog: PropTypes.bool.isRequired,
};

SideNavFooterMenu.defaultProps = {
  toggleBottomSheet: null,
  bottomSheetIcon: null,
};
