import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '../Snackbar';
import FormHolderImage from './FormHolderImage';
import './form-holder.css';

export default function FormHolder({ children, subText }) {
  return (
    <div className="hm-form-holder hm-aligner" style={{ height: '100vh' }}>
      <div className="row middle-xs hm-aligner-item hm-no-padding hm-no-margin">
        <div className="col-xs-offset-2 col-sm-offset-1 col-xs-8 col-sm-4 col-md-4 col-lg-2 hm-no-padding">
          {children}
        </div>
        <div className="col-xs-2 col-sm-2" />
        <div className="col-xs-12 col-sm-5 col-md-5 col-lg-7 hm-no-padding hm-form-holder-image">
          <FormHolderImage subText={subText} />
        </div>
        <Snackbar />
      </div>
    </div>
  );
}

FormHolder.propTypes = {
  children: PropTypes.element.isRequired,
  subText: PropTypes.string,
};

FormHolder.defaultProps = {
  subText: '',
};
