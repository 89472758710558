import { connect } from 'react-redux';
import ForgotPasswordForm from './ForgotPasswordForm';
import { resetPassword as _resetPassword } from '../actions/passwordActions';
function mapDispatchToProps(dispatch) {
  return {
    resetPassword: function resetPassword(email) {
      dispatch(_resetPassword(email));
    }
  };
}
function mapStateToProps(state, ownProps) {
  return {
    isResettingPassword: state.auth.isResettingPassword,
    subText: ownProps.subText
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);