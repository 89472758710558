import React from 'react';
import PropTypes from 'prop-types';
import FormHolder from '../FormHolder/FormHolder';
import ChangePasswordForm from './ChangePasswordFormContainer';

export default function ChangePasswordPage({ subText, location }) {
  return (
    <FormHolder subText={subText}>
      <div>
        <h1>Set your password</h1>
        <ChangePasswordForm location={location} />
      </div>
    </FormHolder>
  );
}

ChangePasswordPage.propTypes = {
  subText: PropTypes.string,
  location: PropTypes.shape({
    query: { email: PropTypes.string, key: PropTypes.string },
  }).isRequired,
};

ChangePasswordPage.defaultProps = {
  subText: 'PLATFORM',
};
