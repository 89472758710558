import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function UserStats({ iconColor }) {
  return (
    <g>
      <path
        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
        transform="scale(0.6) translate(0, -4)"
        fill={iconColor}
      />
      <path
        stroke="transparent"
        fill={iconColor}
        d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z"
      />
    </g>
  );
}

UserStats.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(UserStats, 'hm-fill-icon');
