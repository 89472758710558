import { connect } from 'react-redux';
import App from './App';
import { verifyLogin as _verifyLogin } from '../actions/authActions';
function mapDispatchToProps(dispatch) {
  return {
    verifyLogin: function verifyLogin() {
      return dispatch(_verifyLogin(null, false));
    }
  };
}
export default connect(null, mapDispatchToProps)(App);