import Icons from './Icons';
import Clock from './Clock';
import Home from './Home';
import Logout from './Logout';
import Settings from './Settings';
import Stats from './Stats';
import Dashboard from './Dashboard';
import Tasks from './Tasks';
import Person from './Person';
import UserStats from './UserStats';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import FileDownload from './FileDownload';
import Qualifications from './Qualifications';
import Chaining from './Chaining';
import Files from './Files';
export default function (icon) {
  switch (icon) {
    case Icons.CLOCK:
      return Clock;
    case Icons.HOME:
      return Home;
    case Icons.LOGOUT:
      return Logout;
    case Icons.SETTINGS:
      return Settings;
    case Icons.STATS:
      return Stats;
    case Icons.DASHBOARD:
      return Dashboard;
    case Icons.TASKS:
      return Tasks;
    case Icons.PERSON:
      return Person;
    case Icons.USER_STATS:
      return UserStats;
    case Icons.CHEVRON_LEFT:
      return ChevronLeft;
    case Icons.CHEVRON_RIGHT:
      return ChevronRight;
    case Icons.FILE_DOWNLOAD:
      return FileDownload;
    case Icons.QUALIFICATIONS:
      return Qualifications;
    case Icons.CHAINING:
      return Chaining;
    case Icons.FILES:
      return Files;
    default:
      throw new Error("Icon [".concat(icon, "] not supported"));
  }
}