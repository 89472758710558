import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function Clock({ iconColor }) {
  return (
    <g
      stroke={iconColor}
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M23 12.393c0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11 11 4.925 11 11z" />
      <path d="M12 4.536v7.857h5.5" />
    </g>
  );
}

Clock.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(Clock);
