import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MuiSnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core';
import Button from '../Button';

const styles = theme => ({
  success: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.secondary.contrastText,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  message: {
    whiteSpace: 'pre-line',
    fontWeight: 400,
    flexGrow: 1,
    textAlign: 'center',
  },
});

function SnackbarContent({ classes, message, variant, actionLabel, onActionClick }) {
  const action = (actionLabel && onActionClick)
    ? <Button size="small" onClick={onActionClick}>{actionLabel}</Button>
    : null;


  return (
    <MuiSnackbarContent
      className={classNames(classes[variant], classes.container)}
      classes={{ message: classes.message }}
      aria-describedby="client-snackbar"
      message={message}
      action={action}
    />
  );
}

SnackbarContent.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  message: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['success', 'error']).isRequired,
  actionLabel: PropTypes.string,
  onActionClick: PropTypes.func,
};

SnackbarContent.defaultProps = {
  actionLabel: null,
  onActionClick: null,
};

export default withStyles(styles)(SnackbarContent);
