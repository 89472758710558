import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { browserHistory } from 'react-router';
import { checkHttpStatus, isJson } from '../utils/authFetch';
import { openSnackbar } from './snackbarActions';
import authActionTypes from './authActionTypes';
function resetPasswordRequest() {
  return {
    type: authActionTypes.RESET_PASSWORD_REQUEST
  };
}
export function resetPasswordSuccess() {
  return {
    type: authActionTypes.RESET_PASSWORD_SUCCESS
  };
}
export function resetPasswordFailure(error, reason) {
  return function (dispatch) {
    dispatch(openSnackbar("Login failed: ".concat(reason || error.response.statusText, " (Status: ").concat(error.response.status, ")"), false));
    dispatch({
      type: authActionTypes.RESET_PASSWORD_FAILURE
    });
  };
}
export function resetPasswordFailureAndRedirect(error) {
  return function (dispatch) {
    browserHistory.push('/login');
    if (isJson(error.response)) {
      return error.response.json().then(function (r) {
        return dispatch(resetPasswordFailure(error, r.error));
      });
    }
    return dispatch(resetPasswordFailure(error));
  };
}
export function resetPasswordSuccessAndRedirect() {
  return function (dispatch) {
    browserHistory.push('/login');
    dispatch(resetPasswordSuccess());
    dispatch(openSnackbar('Password reset requested, check your email for a reset password link.'));
  };
}
export function resetPassword(email) {
  return function (dispatch, getState) {
    dispatch(showLoading());
    dispatch(resetPasswordRequest());
    var _getState = getState(),
      config = _getState.config;
    var resetUrl = "".concat(window.location.origin, "/change-password");
    var formData = new FormData();
    formData.append('email', email);
    formData.append('sourceUrl', resetUrl);
    return fetch("".concat(config.authUrl, "/password/reset"), {
      method: 'post',
      mode: 'cors',
      body: formData
    }).then(checkHttpStatus).then(function () {
      return dispatch(resetPasswordSuccessAndRedirect());
    }).catch(function (error) {
      return dispatch(resetPasswordFailureAndRedirect(error));
    }).finally(function () {
      return dispatch(hideLoading());
    });
  };
}
export function verifyResetFailure() {
  return {
    type: authActionTypes.VERIFY_RESET_FAILURE
  };
}
export function verifyResetFailureAndRedirect() {
  return function (dispatch) {
    browserHistory.push('/forgot-password?expired');
    dispatch(verifyResetFailure());
    dispatch(openSnackbar('Password reset link has expired, please request it again', false));
  };
}
export function verifyReset(email, key) {
  return function (dispatch, getState) {
    var _getState2 = getState(),
      config = _getState2.config;
    var formData = new FormData();
    formData.append('email', email);
    formData.append('key', key);
    return fetch("".concat(config.authUrl, "/password/verify"), {
      method: 'post',
      mode: 'cors',
      body: formData
    }).then(checkHttpStatus).catch(function () {
      return dispatch(verifyResetFailureAndRedirect());
    });
  };
}
function changePasswordRequest() {
  return {
    type: authActionTypes.CHANGE_PASSWORD_REQUEST
  };
}
export function changePasswordFailure() {
  return {
    type: authActionTypes.CHANGE_PASSWORD_FAILURE
  };
}
export function changePasswordSuccess() {
  return {
    type: authActionTypes.CHANGE_PASSWORD_SUCCESS
  };
}
export function changePasswordSuccessAndRedirect() {
  return function (dispatch) {
    browserHistory.push('/login');
    dispatch(changePasswordSuccess());
    dispatch(openSnackbar('Password successfully changed'));
  };
}
export function changePassword(email, key, password) {
  return function (dispatch, getState) {
    dispatch(showLoading());
    dispatch(changePasswordRequest());
    var _getState3 = getState(),
      config = _getState3.config;
    var formData = new FormData();
    formData.append('email', email);
    formData.append('key', key);
    formData.append('password', password);
    return fetch("".concat(config.authUrl, "/password/change"), {
      method: 'post',
      mode: 'cors',
      body: formData
    }).then(checkHttpStatus).then(function () {
      return dispatch(changePasswordSuccessAndRedirect());
    }).catch(function () {
      dispatch(changePasswordFailure());
      dispatch(openSnackbar('Failed to change password', false));
    }).finally(function () {
      return dispatch(hideLoading());
    });
  };
}