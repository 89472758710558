import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { browserHistory } from 'react-router';
import jwtDecode from 'jwt-decode';
import auth0 from 'auth0-js';
import crypto from 'crypto';
import { checkHttpStatus, isJson } from '../utils/authFetch';
import { isValidToken, refreshAuthIfNeeded, invalidateAuth } from '../utils/authUtils';
import { clearMenuItems } from './menuItemActions';
import { openSnackbar } from './snackbarActions';
import authActionTypes from './authActionTypes';
var ssoStateName = 'ssoState';
function loginUserSuccess() {
  return {
    type: authActionTypes.LOGIN_USER_SUCCESS
  };
}
function loginUserSuccessAndSetToken(token, refresh) {
  localStorage.setItem('token', token);
  if (refresh) {
    localStorage.setItem('refresh', refresh);
  }
  return loginUserSuccess();
}
function loginUserFailure(error, reason) {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh');
  return function (dispatch) {
    dispatch(openSnackbar("Login failed: ".concat(reason || error.response.statusText, " (Status: ").concat(error.response.status, ")"), false));
    return dispatch({
      type: authActionTypes.LOGIN_USER_FAILURE
    });
  };
}
function magicUserFailure(error, reason) {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh');
  return function (dispatch) {
    dispatch(openSnackbar("Login failed: ".concat(reason || error.response.statusText, " (Status: ").concat(error.response.status, ")"), false));
    return dispatch({
      type: authActionTypes.MAGIC_USER_FAILURE
    });
  };
}
function loginUserFailureAndRedirect(error, reason) {
  return function (dispatch) {
    dispatch(loginUserFailure(error, reason));
    browserHistory.replace('/login');
  };
}
function loginUserRequest() {
  return {
    type: authActionTypes.LOGIN_USER_REQUEST
  };
}
function magicUserRequest() {
  return {
    type: authActionTypes.MAGIC_USER_REQUEST
  };
}
function logoutImpl() {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh');
  return {
    type: authActionTypes.LOGOUT_USER
  };
}
function ssoGetAuth0Token(code) {
  return function (dispatch, getState) {
    var _getState = getState(),
      config = _getState.config;
    return new Promise(function (resolve) {
      var auth = new auth0.Authentication({
        domain: config.auth0Domain,
        clientID: config.auth0ClientId
      });
      auth.oauthToken({
        grantType: 'authorization_code',
        code: code,
        redirectUri: window.location.origin
      }, function (error, result) {
        if (error || !result || !result.accessToken) {
          // Failed to get a bearer token so bail out to the login screen
          throw error || 'Invalid response. Expected token.';
        }
        resolve(result.accessToken);
      });
    });
  };
}
function ssoExchangeTokensFailure(error, reason) {
  localStorage.removeItem(ssoStateName);
  return function (dispatch) {
    return dispatch(openSnackbar("Login failed: ".concat(reason || error.response.statusText, " (Status: ").concat(error.response.status, ")"), false));
  };
}
function ssoExchangeTokens(email, useRefreshToken, auth0Token) {
  return function (dispatch, getState) {
    var _getState2 = getState(),
      config = _getState2.config;
    // Exchange the auth0 bearer token for a Hivemind token
    var url = "".concat(config.authUrl, "/sso/authorize");
    return fetch(url, {
      headers: {
        Authorization: "Bearer ".concat(auth0Token),
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      method: 'post',
      body: JSON.stringify({
        email: email,
        includeRefresh: useRefreshToken
      })
    }).then(checkHttpStatus).then(function (response) {
      return response.json();
    }).catch(function (error) {
      if (error && error.response && error.response.status === 404) {
        dispatch(ssoExchangeTokensFailure(error, 'Email address not found'));
        throw error;
      }
      throw error;
    });
  };
}
export function logout() {
  return function (dispatch) {
    return invalidateAuth().catch(function () {}).then(function () {
      return dispatch(logoutImpl());
    });
  };
}
export function logoutAndRedirect() {
  var redirectTo = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '/login';
  return function (dispatch) {
    return dispatch(logout()).then(function () {
      return dispatch(clearMenuItems());
    }).then(function () {
      return browserHistory.push(redirectTo);
    });
  };
}
function userHasClaim(token, claim) {
  var userClaims = jwtDecode(token).roles || [];
  return userClaims.includes(claim);
}
function attemptLogin(dispatch, token, refresh, claim, redirectTo, onSuccessAction, onFailureAction) {
  try {
    if (userHasClaim(token, claim)) {
      dispatch(onSuccessAction(token, refresh));
      if (redirectTo) {
        browserHistory.replace(redirectTo);
      }
    } else {
      var response = {
        status: 403,
        statusText: 'Access denied'
      };
      if (onFailureAction) {
        onFailureAction({
          response: response
        });
      } else {
        dispatch(loginUserFailure({
          response: response
        }));
      }
    }
  } catch (e) {
    if (onFailureAction) {
      dispatch(onFailureAction(e));
    } else {
      dispatch(loginUserFailure({
        response: {
          status: 403,
          statusText: 'Invalid token'
        }
      }));
    }
  }
}
var generateRandomToken = function generateRandomToken() {
  return crypto.randomBytes(Math.ceil(64 * 0.75)).toString('base64').slice(0, 64);
};
function generateCsrfToken(email, useRefreshToken) {
  if (!email) {
    throw new Error('Email not specified');
  }
  var state = {
    token: generateRandomToken(),
    email: email,
    useRefreshToken: useRefreshToken
  };
  var result = JSON.stringify(state);
  localStorage.setItem(ssoStateName, result);
  return result;
}
export function loginUser(email, password, claim) {
  var redirect = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '/';
  var useRefreshToken = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  return function (dispatch, getState) {
    dispatch(showLoading());
    dispatch(loginUserRequest());
    var _getState3 = getState(),
      config = _getState3.config;
    var formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    var url = "".concat(config.authUrl, "/login");
    if (useRefreshToken) {
      url += '?includeRefresh=true';
    }
    return fetch(url, {
      method: 'post',
      mode: 'cors',
      body: formData
    }).then(checkHttpStatus).then(function (response) {
      return response.json();
    }).then(function (json) {
      return attemptLogin(dispatch, json.token, json.refresh, claim, redirect, loginUserSuccessAndSetToken);
    }).catch(function (error) {
      if (isJson(error.response)) {
        return error.response.json().then(function (r) {
          return dispatch(loginUserFailure(error, r.error));
        });
      }
      return dispatch(loginUserFailure(error));
    }).finally(function () {
      return dispatch(hideLoading());
    });
  };
}
export function verifyMagicLink(key, claim) {
  var redirect = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '/';
  return function (dispatch, getState) {
    dispatch(showLoading());
    dispatch(magicUserRequest());
    var _getState4 = getState(),
      config = _getState4.config;
    var formData = new FormData();
    formData.append('key', key);
    return fetch("".concat(config.authUrl, "/magic"), {
      method: 'post',
      mode: 'cors',
      body: formData
    }).then(checkHttpStatus).then(function (response) {
      return response.json();
    }).then(function (json) {
      return attemptLogin(dispatch, json.token, json.refresh, claim, redirect, loginUserSuccessAndSetToken);
    }).catch(function (error) {
      if (isJson(error.response)) {
        return error.response.json().then(function (r) {
          return dispatch(magicUserFailure(error, r.error));
        });
      }
      return dispatch(magicUserFailure(error));
    }).finally(function () {
      return dispatch(hideLoading());
    });
  };
}
function ssoLoadConnection(email) {
  return function (dispatch, getState) {
    var _getState5 = getState(),
      config = _getState5.config;
    var url = "".concat(config.authUrl, "/sso/connection");
    return fetch(url, {
      body: JSON.stringify({
        email: email
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      method: 'post'
    }).then(checkHttpStatus).then(function (response) {
      return response.json();
    });
  };
}
export function ssoLoginEnterprise(email, useRefreshToken) {
  return function (dispatch, getState) {
    dispatch(loginUserRequest());
    dispatch(ssoLoadConnection(email)).then(function (connection) {
      // Initialise Auth0
      var _getState6 = getState(),
        config = _getState6.config;
      var webAuth = new auth0.WebAuth({
        domain: config.auth0Domain,
        clientID: config.auth0ClientId
      });

      // Generated and store CSRF token
      var state = generateCsrfToken(email, useRefreshToken);
      var encodedState = encodeURI(state);
      webAuth.authorize({
        audience: config.auth0Audience,
        connection: connection.nameId,
        // Omit this to use Auth0 Universal Login widget
        loginHint: email,
        redirectUri: "".concat(window.location.origin, "/login"),
        responseType: 'code',
        state: encodedState,
        username: email
      });
    }).catch(function (error) {
      dispatch(loginUserFailure(error));
    });
  };
}
export function ssoExchangeToken(code, state, claim, redirect) {
  return function (dispatch) {
    // Validate the request by checking 'state' against the value in local storage.
    // 'state' is the CSRF token
    var localState = localStorage.getItem(ssoStateName);
    var decodedState = decodeURI(state);
    localStorage.removeItem(ssoStateName);
    if (localState !== decodedState) {
      // Failed to validate auth0 callback, send the user back to the login screen
      return dispatch(loginUserFailureAndRedirect({
        response: {
          status: 403,
          statusText: 'Access denied'
        }
      }, 'Invalid state response from SSO callback'));
    }

    // Extract the email from the state
    var _JSON$parse = JSON.parse(decodedState),
      email = _JSON$parse.email,
      useRefreshToken = _JSON$parse.useRefreshToken;
    return dispatch(ssoGetAuth0Token(code)).then(function (token) {
      return dispatch(ssoExchangeTokens(email, useRefreshToken, token));
    }).then(function (json) {
      return attemptLogin(dispatch, json.token, json.refresh, claim, redirect, loginUserSuccessAndSetToken, function (response) {
        return dispatch(loginUserFailureAndRedirect(response));
      });
    }).catch(function (error) {
      // eslint-disable-next-line no-console
      console.error("Auth: ".concat(error));
      browserHistory.replace('/login');
    });
  };
}
function getTokenIfValid() {
  var token = localStorage.getItem('token');
  return isValidToken(token) ? token : undefined;
}
function getRefreshIfValid() {
  var refresh = localStorage.getItem('refresh');
  return isValidToken(refresh) ? refresh : undefined;
}
function startAuthInitialisation() {
  return {
    type: authActionTypes.AUTH_INITIALISATION_START
  };
}
function endAuthInitialisation() {
  return {
    type: authActionTypes.AUTH_INITIALISATION_END
  };
}
export function verifyLogin(redirectTo) {
  var shouldRedirect = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return function (dispatch) {
    dispatch(startAuthInitialisation());
    return refreshAuthIfNeeded().then(function () {
      var token = getTokenIfValid();
      var refresh = getRefreshIfValid();
      var refreshExistsAndHasLessThan12HoursRemaining = refresh && !isValidToken(refresh, 60 * 60 * 12);
      if (refreshExistsAndHasLessThan12HoursRemaining && shouldRedirect) {
        return dispatch(logoutAndRedirect(redirectTo));
      }
      if (refreshExistsAndHasLessThan12HoursRemaining) {
        return dispatch(logout());
      }
      if (token) {
        return dispatch(loginUserSuccess(token));
      }
      if (shouldRedirect) {
        return dispatch(logoutAndRedirect(redirectTo));
      }
      return dispatch(logout());
    }).finally(function () {
      return dispatch(endAuthInitialisation());
    });
  };
}
function registerUserRequest() {
  return {
    type: authActionTypes.REGISTER_USER_REQUEST
  };
}
function registerUserSuccess() {
  return {
    type: authActionTypes.REGISTER_USER_SUCCESS
  };
}
function registerUserSuccessAndSetToken(token, refresh) {
  localStorage.setItem('token', token);
  localStorage.setItem('refresh', refresh);
  return registerUserSuccess();
}
function registerFailure(error, reason) {
  localStorage.removeItem('token');
  return {
    type: authActionTypes.REGISTER_USER_FAILURE,
    payload: {
      status: error && error.response && error.response.status,
      reason: reason
    }
  };
}
export function registerAndLogin(name, email, password, company, consent, claim) {
  var redirectTo = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : null;
  var useRefreshToken = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : false;
  return function (dispatch, getState) {
    dispatch(showLoading());
    dispatch(registerUserRequest());
    var _getState7 = getState(),
      config = _getState7.config;
    var formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('password', password);
    if (company) {
      formData.append('company', company);
    }
    if (consent) {
      if (consent.acceptNewsletter) {
        formData.append('newsletterSignup', consent.acceptNewsletter);
      }
      if (consent.acceptTermsAndConditions) {
        formData.append('acceptTerms', consent.acceptTermsAndConditions);
      }
    }
    var url = "".concat(config.authUrl, "/register");
    if (useRefreshToken) {
      url += '?includeRefresh=true';
    }
    return fetch(url, {
      method: 'post',
      mode: 'cors',
      body: formData
    }).then(checkHttpStatus).then(function (response) {
      return response.json();
    }).then(function (json) {
      attemptLogin(dispatch, json.token, json.refresh, claim, redirectTo, registerUserSuccessAndSetToken);
    }).catch(function (error) {
      if (isJson(error.response)) {
        return error.response.json().then(function (r) {
          return dispatch(registerFailure(error, r.error));
        });
      }
      return dispatch(registerFailure(error));
    }).finally(function () {
      return dispatch(hideLoading());
    });
  };
}