import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function Pause({ iconColor }) {
  return (
    <g
      stroke={iconColor}
      strokeWidth="1.5"
      fill={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
    </g>
  );
}

Pause.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(Pause);
