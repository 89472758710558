import { connect } from 'react-redux';
import ThemeMenu from './ThemeMenu';
import { setCurrentTheme } from '../../actions/themeActions';
function mapStateToProps(state) {
  return {
    show: state.ui.theme.allowChange
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onSetTheme: function onSetTheme(theme) {
      return dispatch(setCurrentTheme(theme));
    }
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ThemeMenu);