import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function ChevronRight({ iconColor }) {
  return (
    <g
      stroke={iconColor}
      strokeWidth="1.5"
      fill={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
    </g>
  );
}

ChevronRight.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(ChevronRight, 'hm-fill-icon');
