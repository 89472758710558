import React from 'react';
import PropTypes from 'prop-types';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ResetIcon from 'mdi-material-ui/Restart';
import OpenInNewWindowIcon from '@material-ui/icons/OpenInNew';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import { Grid } from '@material-ui/core';
import Toolbar from '../Toolbar';
import ToolbarButton from '../Toolbar/ToolbarButton';
import Divider from '../Toolbar/Divider';

export default function ImageViewerToolbar({
  zoomIn,
  zoomOut,
  canZoomIn,
  canZoomOut,
  reset,
  canReset,
  wheelDisabled,
  setWheelDisabled,
  imageSrc,
}) {
  return (
    <Toolbar>
      <Grid container direction="row" alignItems="center">
        <Grid item xs>
          <Grid container>
            <ToolbarButton
              onClick={zoomIn}
              tooltip="Zoom In"
              disabled={!canZoomIn}
              icon={ZoomInIcon}
            />
            <ToolbarButton
              onClick={zoomOut}
              tooltip="Zoom Out"
              disabled={!canZoomOut}
              icon={ZoomOutIcon}
            />
            <ToolbarButton
              onClick={reset}
              tooltip="Reset Zoom"
              disabled={!canReset}
              icon={ResetIcon}
            />
            <Divider />
            <ToolbarButton
              onClick={() => setWheelDisabled(!wheelDisabled)}
              tooltip={wheelDisabled ? 'Enable scroll/pinch zoom' : 'Disable scroll/pinch zoom'}
              icon={wheelDisabled ? Lock : LockOpen}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
              <ToolbarButton
                onClick={() => window.open(imageSrc, '_blank', 'noopener, noreferrer')}
                tooltip="Open image in new tab"
                icon={OpenInNewWindowIcon}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

ImageViewerToolbar.propTypes = {
  zoomIn: PropTypes.func.isRequired,
  zoomOut: PropTypes.func.isRequired,
  canZoomIn: PropTypes.bool.isRequired,
  canZoomOut: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  canReset: PropTypes.bool.isRequired,
  wheelDisabled: PropTypes.bool.isRequired,
  setWheelDisabled: PropTypes.func.isRequired,
  imageSrc: PropTypes.string.isRequired,
};
