import React, { useState } from 'react';
import ChangelogIcon from '@material-ui/icons/SwapHoriz';
import { Tooltip } from '@material-ui/core';
import { Dialog } from '../../Dialog';
import IconButton from '../../IconButton';
import ChangelogDialogContent from './ChangelogDialogContent';

function ChangelogMenu() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  return <>
    <Tooltip placement="right" title="Changelog">
      <div>
        <IconButton Icon={ChangelogIcon} onClick={handleOpen} color="default" role="button" accessibilityLabel="Changelog" />
      </div>
    </Tooltip>
    <span className="hm-nav-title">Changelog</span>
    <Dialog isOpen={isDialogOpen} onClose={handleClose} maxWidth="md" className="hm" draggable>
      <ChangelogDialogContent />
    </Dialog>
  </>;
}

export default ChangelogMenu;
