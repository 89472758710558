import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '../../Button';

export default function HivemindLoginForm({
  redirect,
  isAuthenticating,
  login,
  onForgotPassword,
}) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [useRefreshToken, setUseRefreshToken] = useState(false);

  return (
    <div>
      <form style={{ paddingTop: '1em' }}>
        <div>
          <TextField
            placeholder="Email"
            fullWidth
            value={username}
            onChange={event => setUsername(event.target.value)}
          />
        </div>
        <div style={{ height: '1em', width: '100%' }} />

        <div>
          <TextField
            placeholder="Password"
            fullWidth
            type="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
        </div>
        <div style={{ height: '0.5em', width: '100%' }} />

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid  */}
            <Link
              id="forgotPasswordLink"
              component="button"
              variant="body1"
              type="button"
              onClick={onForgotPassword}
              role="button"
              style={{
                fontSize: '0.7em',
                fontWeight: 600,
              }}
            >
              Forgot password?
            </Link>
          </div>
          <div style={{ flexGrow: 1 }} />
          <div>
            <label>
              <span
                style={{
                  fontSize: '0.7em',
                  fontWeight: 600,
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
              >
                Keep me logged in
              </span>
              <Checkbox
                checked={useRefreshToken}
                onChange={event => setUseRefreshToken(event.target.checked)}
                style={{ padding: '0 0 0 4px' }}
                color="primary"
              />
            </label>
          </div>
        </div>

        <div style={{ paddingTop: '1em' }}>
          <Button
            label="Sign in"
            type="submit"
            className="hm-button"
            disabled={isAuthenticating}
            fullWidth
            onClick={(e) => {
              e.preventDefault();
              login(username, password, redirect, useRefreshToken);
            }}
          >
            Sign in
          </Button>
        </div>
      </form>
    </div>
  );
}

HivemindLoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  redirect: PropTypes.string,
  onForgotPassword: PropTypes.func.isRequired,
};

HivemindLoginForm.defaultProps = {
  redirect: '/',
};
