import transform from 'lodash.transform';
function isLanguageTypeObject(o) {
  var type = typeof o;
  return type === 'object' || type === 'function';
}
function isEmpty(t) {
  return Object.keys(t).length === 0 && t.constructor === Object || Array.isArray(t) && t.length === 0;
}
function isArrayOrObject(t) {
  if (!t) return false;
  return t.constructor === Object || Array.isArray(t);
}
function deepSearch(collection, predicate) {
  var references = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new Set();
  if (isArrayOrObject(collection)) {
    if (references.has(collection)) return {};
    references.add(collection);
  }
  return transform(collection, function (result, val, key) {
    var isMatch = predicate(val, key);
    if (!isMatch && isLanguageTypeObject(val)) {
      // eslint-disable-next-line no-param-reassign
      val = deepSearch(val, predicate, references);
      isMatch = !isEmpty(val);
    }
    if (isMatch) {
      if (Array.isArray(collection)) {
        result.push(val);
      } else {
        // eslint-disable-next-line no-param-reassign
        result[key] = val;
      }
    }
  });
}
export default function containsFunction(collection) {
  var functions = deepSearch(collection, function (x) {
    return typeof x === 'function';
  });
  return functions.length > 0;
}