import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

export function PersonIcon({ iconColor }) {
  return (
    <g>
      <path
        fill={iconColor}
        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
      />
    </g>
  );
}

PersonIcon.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(PersonIcon, 'hm-fill-icon');
