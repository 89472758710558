import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '../Button';

export default function ForgotPassword({ isResettingPassword, resetPassword, onCancel }) {
  const [email, setEmail] = useState('');

  const handleReset = (e) => {
    e.preventDefault();
    resetPassword(email);
  };

  return (
    <form style={{ paddingTop: '1em' }}>
      <TextField
        label="Email"
        placeholder="Email"
        fullWidth
        value={email}
        onChange={event => setEmail(event.target.value)}
      />
      <div style={{ paddingTop: '1em' }} className="row">
        <div className="col-xs-5 hm-no-padding">
          <Button
            color="secondary"
            style={{ width: '100%' }}
            onClick={onCancel}
            disabled={isResettingPassword}
          >
            Cancel
          </Button>
        </div>
        <div className="col-xs-2" />
        <div className="col-xs-5 hm-no-padding">
          <Button
            type="submit"
            style={{ width: '100%' }}
            onClick={e => handleReset(e)}
            disabled={isResettingPassword}
          >
            Reset
          </Button>
        </div>
      </div>
    </form>
  );
}

ForgotPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  isResettingPassword: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};
