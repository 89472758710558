import React from 'react';
import { PropTypes } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { darkGrey } from '@agora/agora-common/styles/colours';

import * as colours from '../../../../styles/colours';
import './agoraHeader.css';

export default function AgoraHeader({ children }) {
  return (
    <Grid
      container
      itemxs={12}
      justifyContent="center"
      alignItems="center"
      style={{
        backgroundColor: colours.pageBackground,
        borderBottom: ` 1px solid ${darkGrey}`,
        maxHeight: '5em',
        padding: '1rem 0',
      }}
    >
      <Grid container item xs={6}>
        <Grid item sm={6} md={4} style={{ maxHeight: '2.5em' }}>
          <img src="/crucial2.svg" alt="CRUCIAL Logo" height={100} />
        </Grid>
      </Grid>
      <Grid container item xs={5} justifyContent="flex-end">
        {children}
      </Grid>
    </Grid>
  );
}

AgoraHeader.propTypes = {
  children: PropTypes.node,
};

AgoraHeader.defaultProps = {
  children: null,
};
