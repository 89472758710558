import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Collapse from '@material-ui/core/Collapse';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';

import { LoadingPanel } from '../Loading';
import HivemindLoginForm from './HivemindLoginForm';
import SSOLoginForm from './SSOLoginForm';

export default function LoginForm({
  claim,
  location,
  isExchangingSsoToken,
  isSsoEnabled,
  onForgotPassword,
  onLoginAttemptComplete,
  redirect,
  ssoCheckCallback,
}) {
  const [useSso, setUseSso] = useState(false);
  const [showHivemindForm, setShowHivemindForm] = useState(true);
  const [showSsoForm, setShowSsoForm] = useState(false);

  if (isSsoEnabled) {
    useEffect(() => {
      ssoCheckCallback(claim, redirect);
    }, []);
  }

  return (
    <div>
      {isExchangingSsoToken
        ? <div>
          <LoadingPanel message="Please wait while we log you in..." isLoading />
        </div>
        : <div>

          <Collapse in={showHivemindForm} onExited={() => setShowSsoForm(true)}>
            <HivemindLoginForm
              claim={claim}
              location={location}
              onForgotPassword={onForgotPassword}
              onLoginAttemptComplete={onLoginAttemptComplete}
              redirect={redirect}
            />
          </Collapse>

          {isSsoEnabled && <div>
            <Collapse in={showSsoForm} onExited={() => setShowHivemindForm(true)}>
              <SSOLoginForm location={location} />
            </Collapse>
            <Grid container justifyContent="center" style={{ paddingTop: '1em' }}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid  */}
              <Link
                id="ssoLink"
                component="button"
                variant="body1"
                type="button"
                onClick={() => {
                  const newUseSsoValue = !useSso;

                  setUseSso(newUseSsoValue);

                  if (newUseSsoValue) {
                    setShowHivemindForm(false);
                  } else {
                    setShowSsoForm(false);
                  }
                }}
                role="button"
                style={{
                  fontSize: '0.7em',
                  fontWeight: 600,
                }}
              >
                <span>
                  {!useSso && 'Login with single sign-on (SSO) instead'}
                  {useSso && 'Login with password instead'}
                </span>
              </Link>
            </Grid>
          </div>}
        </div>
      }
    </div>
  );
}

LoginForm.propTypes = {
  claim: PropTypes.string.isRequired,
  isExchangingSsoToken: PropTypes.bool.isRequired,
  isSsoEnabled: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    query: PropTypes.object,
  }).isRequired,
  onForgotPassword: PropTypes.func.isRequired,
  onLoginAttemptComplete: PropTypes.func,
  ssoCheckCallback: PropTypes.func.isRequired,
  redirect: PropTypes.string,
};

LoginForm.defaultProps = {
  onLoginAttemptComplete: null,
  redirect: '/',
};
