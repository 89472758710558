import React, { useEffect } from 'react';
import trackPage from './TrackPage';

export default function connectPageTracking(
  WrappedComponent,
  { name,
    category,
    properties,
    options,
    callback },
) {
  return function WithAnalyticsComponent(props) {
    useEffect(() => {
      trackPage({ name, category, properties, options, callback });
    }, []);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...props} />;
  };
}
