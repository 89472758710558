import { connect } from 'react-redux';
import SideNavFooterMenu from './SideNavFooterMenu';
import { logoutAndRedirect as _logoutAndRedirect } from '../actions/authActions';
function mapStateToProps(state) {
  return {
    showThemeMenu: state.ui.theme.allowChange,
    appFilterTypes: state.appFilters ? state.appFilters.filterTypes : []
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logoutAndRedirect: function logoutAndRedirect() {
      dispatch(_logoutAndRedirect());
    }
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SideNavFooterMenu);