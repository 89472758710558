import { connect } from 'react-redux';
import AuthenticatedComponent from './AuthenticatedComponent';
import { verifyLogin as _verifyLogin } from '../actions/authActions';
export default function requireAuthentication(Component) {
  function mapStateToProps(state) {
    return {
      isInitialising: state.auth.isInitialising,
      isAuthenticated: state.auth.isAuthenticated,
      componentRequiringAuthentication: Component
    };
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      verifyLogin: function verifyLogin() {
        var redirectTo = "/login?redirect=".concat(ownProps.location.pathname);
        dispatch(_verifyLogin(redirectTo, true));
      }
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent);
}