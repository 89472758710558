import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './app.css';
import SideNav from '../SideNav/SideNavContainer';

export default function App({ location, route, verifyLogin, children }) {
  useEffect(() => {
    verifyLogin();
  }, [verifyLogin, children]);

  return (
    <div>
      <SideNav
        currentLocation={location}
        bottomSheet={route.bottomSheet}
        bottomSheetIcon={route.bottomSheetIcon}
        showChangelog={route.showChangelog}
      />
      <div className="hm-app container-fluid">
        {children}
      </div>
    </div>
  );
}

App.propTypes = {
  children: PropTypes.element.isRequired,
  verifyLogin: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  route: PropTypes.shape({
    bottomSheet: PropTypes.element,
    bottomSheetIcon: PropTypes.element,
    showChangelog: PropTypes.bool,
  }),
};

App.defaultProps = {
  route: {
    bottomSheet: null,
    bottomSheetIcon: null,
    showChangelog: true,
  },
};
