import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function Qualifications({ iconColor }) {
  return (
    <g
      stroke={iconColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z"/>
    </g>);
}

Qualifications.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(Qualifications, 'hm-fill-icon');


