import React, { useState, Children, isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';

export default function IconMenu({ Icon, children, accessibilityLabel }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        aria-label={accessibilityLabel}
        aria-owns={open ? 'icon-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Icon />
      </IconButton>
      <Menu
        id="icon-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: 200,
          },
        }}
      >
        {
          Children.map(children, (child) => {
            if (isValidElement(child)) {
              const props = {
                onClick: (e) => {
                  if (child.props.onClick) {
                    child.props.onClick(e);
                  }
                  handleClose();
                },
              };
              return cloneElement(child, props);
            }

            return child;
          })
        }
      </Menu>
    </div>
  );
}

IconMenu.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  children: PropTypes.node.isRequired,
  accessibilityLabel: PropTypes.string.isRequired,
};
