import snackbarActionTypes from './snackbarActionTypes';
import { containsFunction } from '../utils';
export function openSnackbar(message) {
  var success = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var actionType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var actionProps = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  if (actionProps) {
    if (!Array.isArray(actionProps)) {
      throw Error('actionProps must be an array of primitive types or objects containing only primitives, arrays and object properties.');
    }
    if (containsFunction(actionProps)) {
      // being protective to ensure redux state is kept pure.
      throw Error('actionProps must not contain functions.');
    }
  }
  return {
    type: snackbarActionTypes.OPEN,
    payload: {
      message: message,
      success: success,
      actionType: actionType,
      actionProps: actionProps
    }
  };
}
export function updateSnackbar(message) {
  return {
    type: snackbarActionTypes.UPDATE,
    payload: {
      message: message
    }
  };
}
export function closeSnackbar() {
  return {
    type: snackbarActionTypes.CLOSE
  };
}