import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function Files({ iconColor }) {
  return (
    <g>
      <path
        d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V8h16v10z"
        fill={iconColor}
        stroke={iconColor}
        fillRule="evenodd"
      />
    </g>
  );
}

Files.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(Files, 'hm-fill-icon');
