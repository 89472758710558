import Themes from '../styles/Themes';
export default function oneOfWithTheme(theme) {
  return function (light, dark, darkBlue) {
    switch (theme) {
      case Themes.DARK:
        return dark;
      case Themes.DARK_BLUE:
        return darkBlue || dark;
      default:
        return light;
    }
  };
}