import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core';
import IconButton from '../IconButton';

const ToolbarTooltip = withStyles({
  tooltip: {
    fontSize: '0.85em',
  },
})(Tooltip);

const ToolbarIconButton = withStyles({
  icon: {
    padding: 8,
    fontSize: '1em',
  },
})(IconButton);

function ToolbarButton({ icon, onClick, disabled, tooltip }) {
  return (
    <ToolbarTooltip title={tooltip} placement="top">
      <div> {/* div required for tooltip to display with disabled button */}
        <ToolbarIconButton
          onClick={onClick}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          color="default"
          disabled={disabled}
          Icon={icon}
          accessibilityLabel={tooltip}
        />
      </div>
    </ToolbarTooltip>
  );
}

ToolbarButton.propTypes = {
  icon: PropTypes.elementType.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
};

ToolbarButton.defaultProps = {
  isSelected: false,
  tooltip: null,
  disabled: false,
};

export default ToolbarButton;
