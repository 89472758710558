import { connect } from 'react-redux';
import { ssoExchangeToken } from '../actions/authActions';
import { isExchangingTokens, isSsoEnabled } from '../utils/authUtils';
import LoginForm from './LoginForm';
function mapDispatchToProps(dispatch) {
  return {
    ssoCheckCallback: function ssoCheckCallback(claim, redirectTo) {
      // Check to see if this is an SSO authorize callback
      if (isExchangingTokens()) {
        var urlParams = new URLSearchParams(window.location.search);
        dispatch(ssoExchangeToken(urlParams.get('code'), urlParams.get('state'), claim, redirectTo));
      }
    }
  };
}
function mapStateToProps(state, ownProps) {
  return {
    isExchangingSsoToken: isExchangingTokens(),
    isSsoEnabled: isSsoEnabled(),
    onLoginAttemptComplete: ownProps.onLoginAttemptComplete,
    redirect: ownProps.location.query.redirect || '/'
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);