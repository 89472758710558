/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Scrollbars } from 'rc-scrollbars';

import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { withStyles, withTheme } from '@agora/agora-common';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';

import Button from '@agora/agora-common/Button';
import LoadingIndicator from '@agora/agora-common/Loading/LoadingIndicator';
import Snackbar from '@agora/agora-common/Snackbar';

import ConfirmationDialog from '../Common/ConfirmationDialog';
import TinyLoadingIndicator from '../../Common/TinyLoadingIndicator/TinyLoadingIndicator';
import { validateEmail } from '../../../utils/Validation';

import * as colours from '../../../styles/colours';
import FormSection from '../Common/FormSection';
import VerticalExpandButton from '../Common/VerticalExpandButton';
import SocialPreferences from './SocialPreferences';

const styles = () => ({
  root: {
    height: '100vh',
    paddingTop: '4em',
  },
  doneIcon: {
    color: colours.completeIconColour,
  },
  textField: {
    margin: '1em 0',
  },
});

function Profile({
  classes,
  email,
  deleteUser,
  fetchData,
  hasSaved,
  id,
  isLoading,
  isSaving,
  name,
  openConsentManager,
  preferences,
  realName,
  save,
  verified,
  sendVerifyEmail,
}) {
  const [formName, setFormName] = useState('');
  const [formRealName, setFormRealName] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [nameErrorText, setNameErrorText] = useState('');
  const [emailErrorText, setEmailErrorText] = useState('');
  const [emailChangedPopupOpen, setEmailChangedPopupOpen] = useState(false);
  const [deleteExpanded, setDeleteExpanded] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);

  const [notifyMarketStatusChanges, setNotifyMarketStatusChanges] = useState(false);
  const [notifyNewComment, setNotifyNewComment] = useState(false);
  const [notifyReplyToComment, setNotifyReplyToComment] = useState(false);

  const [socialInviteToOtherMarkets, setSocialInviteToOtherMarkets] = useState(false);
  const [socialGitHubUrl, setSocialGitHubUrl] = useState('');
  const [socialGoogleScholarUrl, setSocialGoogleScholarUrl] = useState('');
  const [socialLinkedInUrl, setSocialLinkedInUrl] = useState('');
  const [socialOther, setSocialOther] = useState('');
  const [socialGitHubUrlErrorText, setSocialGitHubUrlErrorText] = useState('');
  const [socialGoogleScholarUrlErrorText, setSocialGoogleScholarUrlErrorText] = useState('');
  const [socialLinkedInUrlErrorText, setSocialLinkedInUrlErrorText] = useState('');
  const [socialOtherErrorText, setSocialOtherErrorText] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setFormName(name);
      setFormRealName(realName);
      setFormEmail(email);
      setNotifyMarketStatusChanges(preferences.notificationPreferences.marketStatusChanges);
      setNotifyNewComment(preferences.notificationPreferences.newComment);
      setNotifyReplyToComment(preferences.notificationPreferences.replyToComment);
      setSocialInviteToOtherMarkets(preferences.socialPreferences.inviteToOtherMarkets);
      setSocialGitHubUrl(preferences.socialPreferences.gitHubUrl);
      setSocialGoogleScholarUrl(preferences.socialPreferences.googleScholarUrl);
      setSocialLinkedInUrl(preferences.socialPreferences.linkedInUrl);
      setSocialOther(preferences.socialPreferences.other);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!verified) {
      // Email has changed and we just saved
      setEmailChangedPopupOpen(true);
    }
  }, [verified]);

  const validatePage = () => {
    const maxUrlLength = 2048;
    let valid = true;

    if (!formName) {
      setNameErrorText('Enter a your name');
      valid = false;
    } else {
      setNameErrorText('');
    }

    if (!email) {
      setEmailErrorText('Enter your email address');
      valid = false;
    } else if (!validateEmail(formEmail)) {
      setEmailErrorText('Enter a valid email address');
      valid = false;
    } else {
      setEmailErrorText('');
    }

    if (socialGitHubUrl && socialGitHubUrl.length > maxUrlLength) {
      setSocialGitHubUrlErrorText(`Url must be ${maxUrlLength} characters or less`);
      valid = false;
    } else {
      setSocialGitHubUrlErrorText('');
    }

    if (socialGoogleScholarUrl && socialGoogleScholarUrl.length > maxUrlLength) {
      setSocialGoogleScholarUrlErrorText(`Url must be ${maxUrlLength} characters or less`);
      valid = false;
    } else {
      setSocialGoogleScholarUrlErrorText('');
    }

    if (socialLinkedInUrl && socialLinkedInUrl.length > maxUrlLength) {
      setSocialLinkedInUrlErrorText(`Url must be ${maxUrlLength} characters or less`);
      valid = false;
    } else {
      setSocialLinkedInUrlErrorText('');
    }

    if (socialOther && socialOther.length > 4096) {
      setSocialOtherErrorText('Text must be 4096 characters or less');
      valid = false;
    } else {
      setSocialOtherErrorText('');
    }

    return valid;
  };

  return (
    <Grid classes={{ container: classes.root }} container>
      {isLoading
        ? <LoadingIndicator style={{ display: 'flex', alignItems: 'center' }} />
        : (
          <Scrollbars
            autoHide
            style={{ width: '100%' }}
          >
            <Grid container justifyContent="center">
              <Grid xl={4} lg={6} md={10} xs={12} item>
                <Grid container direction="column" style={{ height: '100%' }}>
                  <FormSection>
                    <h1>Profile</h1>

                    <div>
                      <FormSection title="Name">
                        Your display name will be shown to other players on market leaderboards. The real name field will be used by us
                        if we contact you directly, for example, inviting you to play in future markets.
                        <TextField
                          defaultValue={name}
                          disabled={isSaving}
                          label="Display Name (shown to other users)"
                          fullWidth
                          error={!!nameErrorText}
                          helperText={nameErrorText}
                          onChange={event => setFormName(event.target.value)}
                          classes={{ root: classes.textField }}
                        />

                        <TextField
                          defaultValue={realName}
                          disabled={isSaving}
                          label="Real name (hidden from other users)"
                          fullWidth
                          onChange={event => setFormRealName(event.target.value)}
                          classes={{ root: classes.textField }}
                        />
                      </FormSection>

                      <FormSection title="Email">
                        <TextField
                          defaultValue={email}
                          disabled={isSaving}
                          fullWidth
                          placeholder="example@email.com"
                          error={!!emailErrorText}
                          helperText={emailErrorText}
                          onChange={event => setFormEmail(event.target.value)}
                        />
                      </FormSection>

                      <FormSection title="Notifications">
                        Notifications can be sent when certain events happen in markets you are participating in, depending on how the market administrator
                        has configured the market. If you do not wish to receive these notifications you can turn them off here.
                        <FormGroup>
                          <FormControlLabel
                            defaultChecked={preferences.notificationPreferences.marketStatusChanges}
                            control={<Checkbox color="secondary" checked={notifyMarketStatusChanges} onChange={event => setNotifyMarketStatusChanges(event.target.checked)} />}
                            label="Email when market state changes"
                          />
                          <FormControlLabel
                            defaultChecked={preferences.notificationPreferences.notifyNewComment}
                            control={<Checkbox color="secondary" checked={notifyNewComment} onChange={event => setNotifyNewComment(event.target.checked)} />}
                            label="Email when a new comment is posted"
                          />
                          <FormControlLabel
                            defaultChecked={preferences.notificationPreferences.notifyReplyToComment}
                            control={<Checkbox color="secondary" checked={notifyReplyToComment} onChange={event => setNotifyReplyToComment(event.target.checked)} />}
                            label="Email when someone replies to your comment"
                          />
                        </FormGroup>
                      </FormSection>

                      <SocialPreferences
                        defaultInviteToOtherMarkets={preferences.socialPreferences.inviteToOtherMarkets}
                        defaultGitHubUrl={preferences.socialPreferences.gitHubUrl}
                        defaultGoogleScholarUrl={preferences.socialPreferences.googleScholarUrl}
                        defaultLinkedInUrl={preferences.socialPreferences.linkedInUrl}
                        defaultOther={preferences.socialPreferences.other}
                        gitHubUrlErrorText={socialGitHubUrlErrorText}
                        googleScholarUrlErrorText={socialGoogleScholarUrlErrorText}
                        linkedInUrlErrorText={socialLinkedInUrlErrorText}
                        otherErrorText={socialOtherErrorText}
                        onInviteToOtherMarketsChanged={val => setSocialInviteToOtherMarkets(val)}
                        onGithubUrlChanged={val => setSocialGitHubUrl(val)}
                        onGoogleScholarUrlChanged={val => setSocialGoogleScholarUrl(val)}
                        onLinkedInUrlChanged={val => setSocialLinkedInUrl(val)}
                        onOtherChanged={val => setSocialOther(val)}
                        isSaving={isSaving}
                      />

                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          color="primary"
                          disabled={isSaving}
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();

                            if (validatePage()) {
                              const emailChanged = formEmail !== email;

                              save(formName, formRealName, formEmail, {
                                marketStatusChanges: notifyMarketStatusChanges,
                                newComment: notifyNewComment,
                                replyToComment: notifyReplyToComment,
                              },
                              {
                                inviteToOtherMarkets: socialInviteToOtherMarkets,
                                gitHubUrl: socialGitHubUrl,
                                googleScholarUrl: socialGoogleScholarUrl,
                                linkedInUrl: socialLinkedInUrl,
                                other: socialOther,
                              })
                                .then(() => {
                                  if (emailChanged) {
                                    setEmailChangedPopupOpen(true);
                                  }
                                });
                            }
                          }}
                          variant="contained"
                        >
                          <div>
                            {isSaving || 'Save changes'}
                            {isSaving && <TinyLoadingIndicator message="Please wait..." />}
                          </div>
                        </Button>
                        {hasSaved && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <DoneIcon className={classNames(classes.doneIcon)} style={{ padding: '0 0.2em 0 1em' }} />
                          Saved!
                        </div>
                        )}
                      </div>
                    </div>
                  </FormSection>

                  <FormSection>
                    <Link
                      color="primary"
                      component="button"
                      variant="body1"
                      type="button"
                      onClick={openConsentManager}
                    >
                      Change your data settings
                    </Link>
                  </FormSection>

                  <FormSection>
                    *To change your password use the
                    {' '}
                    <Link href="/forgot-password">Forgot Password</Link>
                    {' '}
                    page
                  </FormSection>

                  <FormSection>
                    {isLoading || (
                    <FormSection title={(
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ paddingRight: '0.3em' }}>Delete account</span>
                        <VerticalExpandButton isExpanded={deleteExpanded} onClick={() => setDeleteExpanded(!deleteExpanded)} />
                      </div>
                  )}
                    >
                      <Collapse in={deleteExpanded} collapsedHeight="0em" style={{ width: '100%' }}>
                        <p>
                          Are you sure you don&apos;t want to reconsider? Is there something we can do?
                          {' '}<Link href="mailto:support@hvmd.io" target="_blank">{'Let us know'}</Link>
                          !
                        </p>
                        <p>
                          If you want to go ahead and delete your account, we&apos;re sorry to see you go. A couple of things to note:
                        </p>
                        <ul>
                          <li>Your personal information will be deleted.</li>
                          <li>Account deletion is permanent and cannot be undone.</li>
                        </ul>
                        <p>
                          To delete your account click the button below.
                        </p>
                        <Button
                          color="danger"
                          disabled={isSaving}
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            setDeletePopupOpen(true);
                          }}
                          variant="outlined"
                        >
                          <div>{isSaving || 'Delete'}</div>
                        </Button>
                      </Collapse>
                    </FormSection>
                    )}
                  </FormSection>
                </Grid>
              </Grid>
            </Grid>
          </Scrollbars>
        )
      }
      <Snackbar />
      <ConfirmationDialog
        isOpen={emailChangedPopupOpen}
        confirmText="Refresh"
        onConfirm={() => window.location.reload()}
        onCancel={() => setEmailChangedPopupOpen(false)}
      >
        <span>
          Please confirm your email address by visiting the confirmation link that has been sent to you. If you have have
          lost the confirmation email click
          {' '}
          <Link style={{ cursor: 'pointer' }} onClick={sendVerifyEmail}>here</Link>
          {' '}
          to resend.
        </span>
      </ConfirmationDialog>
      <ConfirmationDialog
        buttonColor="danger"
        isOpen={deletePopupOpen}
        onConfirm={() => deleteUser(id)}
        onCancel={() => setDeletePopupOpen(false)}
      >
        Are you sure you want to delete your account? This cannot be undone!
      </ConfirmationDialog>
    </Grid>
  );
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteUser: PropTypes.func,
  email: PropTypes.string,
  fetchData: PropTypes.func,
  hasSaved: PropTypes.bool,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  name: PropTypes.string,
  realName: PropTypes.string,
  openConsentManager: PropTypes.func.isRequired,
  preferences: PropTypes.object,
  save: PropTypes.func,
  verified: PropTypes.bool,
  sendVerifyEmail: PropTypes.func,
};

Profile.defaultProps = {
  email: null,
  deleteUser: () => {},
  fetchData: () => {},
  id: null,
  isLoading: false,
  isSaving: false,
  hasSaved: false,
  name: null,
  preferences: null,
  realName: null,
  save: () => {},
  verified: true,
  sendVerifyEmail: () => {},
};

export default withStyles(styles)(withTheme(Profile));
