import React from 'react';
import PropTypes from 'prop-types';
import './form-holder-image.css';

export default function FormHolderImage({ subText }) {
  return (
    <svg width="100%" height="100%">
      <defs>
        <mask id="hm-mask" maskUnits="objectBoundingBox">
          <rect x="-5" y="0" width="100%" height="100%" />
          <ellipse height="100%" width="100%" rx="60%" ry="110%" cx="60%" cy="50%" />
        </mask>
      </defs>
      <rect x="0" y="0" width="100%" height="100%" />
      <text x="50%" y="50%" textAnchor="middle">
        <tspan className="hm-logo-title" x="50%">
          CRUCIAL
        </tspan>
        <tspan className="hm-logo-small-title" x="50%" dy="1.4em">
          {subText}
        </tspan>
      </text>
    </svg>
  );
}

FormHolderImage.propTypes = {
  subText: PropTypes.string,
};

FormHolderImage.defaultProps = {
  subText: 'PLATFORM',
};
