import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../Dialog';

function MessageDialog({
  children,
  classes,
  closeButtonText,
  closeOnBackdropClick,
  draggable,
  isOpen,
  onClose,
  maxWidth,
  title,
}) {
  const buttons = [{
    text: closeButtonText || 'Close',
    onClick: onClose,
    color: 'primary',
  }];

  return (
    <Dialog
      buttons={buttons}
      classes={classes}
      isOpen={isOpen}
      closeOnBackdropClick={closeOnBackdropClick}
      maxWidth={maxWidth}
      draggable={draggable}
      onClose={onClose}
      title={title}
    >
      {children}
    </Dialog>
  );
}

MessageDialog.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.shape({}),
  closeOnBackdropClick: PropTypes.bool,
  closeButtonText: PropTypes.string,
  draggable: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

MessageDialog.defaultProps = {
  classes: null,
  closeOnBackdropClick: true,
  closeButtonText: 'Close',
  draggable: false,
  maxWidth: 'sm',
  title: null,
};

export default MessageDialog;
