import jwtDecode from 'jwt-decode';
import { getConfig } from './configUtils';
export function isSsoEnabled() {
  var config = getConfig();
  return config && !!config.auth0Domain && !!config.auth0ClientId && !!config.auth0Audience;
}
export function isExchangingTokens() {
  return window.location.search.includes('code=') && window.location.search.includes('state=');
}
export function isValidToken(token) {
  var requiredLifespan = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  if (!token) {
    return false;
  }
  try {
    var tokenExpiry = jwtDecode(token).exp;
    if (tokenExpiry > new Date().getTime() / 1000 + requiredLifespan) {
      return true;
    }
  } catch (_unused) {
    return false;
  }
  return false;
}
export function userHasClaim(claim) {
  var token = localStorage.getItem('token');
  if (isValidToken(token)) {
    var decodedToken = jwtDecode(token);
    var roles = decodedToken.roles;
    return roles.includes(claim);
  }
  return false;
}
export function getUserId() {
  var token = localStorage.getItem('token');
  if (isValidToken(token)) {
    var decodedToken = jwtDecode(token);
    var sub = decodedToken.sub;
    return sub;
  }
  return null;
}

/**
 * @returns {Promise<void>}
 */
export function invalidateAuth() {
  var config = getConfig();
  if (!!config) {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    var redirectUrl = new URL("/login", window.location.origin);
    localStorage.setItem('redirect', window.location.pathname);
    var logoutUrl = new URL("".concat(config.authUrl, "/logout"));
    logoutUrl.searchParams.set('redirect', redirectUrl.toString());
    window.location.assign(logoutUrl);
  }
  return new Promise(function (_r, j) {
    return setTimeout(function () {
      return j('Could not log out');
    }, 5000);
  });
}
export function refreshAuth() {
  var refresh = localStorage.getItem('refresh');
  var config = getConfig();
  if (isValidToken(refresh) && config) {
    return fetch("".concat(config.authUrl, "/refresh"), {
      method: 'get',
      mode: 'cors',
      headers: {
        Authorization: "Bearer ".concat(refresh),
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    }).then(function (response) {
      return response.json();
    }).then(function (json) {
      return localStorage.setItem('token', json.token);
    });
  }
  return Promise.resolve();
}
function doesTokenNeedRefreshing() {
  var token = localStorage.getItem('token');
  return !isValidToken(token, 60 * 30);
}
var refreshPromise = Promise.resolve();
export function refreshAuthIfNeeded() {
  if (!doesTokenNeedRefreshing()) {
    return Promise.resolve();
  }
  refreshPromise = refreshPromise.then(function () {
    if (doesTokenNeedRefreshing()) {
      return refreshAuth().catch(function () {});
    }
    return Promise.resolve();
  });
  return refreshPromise;
}