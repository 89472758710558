import { withStyles as withStylesMUI } from '@material-ui/core/styles';
import materialNextHivemindTheme from './styles/nextHivemindTheme';
import Themes from './styles/Themes';
export { withTheme } from '@material-ui/core/styles';
export { default as App } from './App';
export { default as Auth } from './Auth';
export { default as Login } from './Login';
export { default as ForgotPassword } from './ForgotPassword';
export { default as ChangePassword } from './ChangePassword';
export { default as ThemeProvider } from './ThemeProvider';
export { default as LoadingBar } from './LoadingBar';
export var withStyles = function withStyles(styles) {
  return withStylesMUI(styles, {
    withTheme: true,
    defaultTheme: materialNextHivemindTheme(Themes.DARK)
  });
};