import 'whatwg-fetch';
import { setDefaultTheme, setAllowThemeChange } from './themeActions';
import themes from '../styles/Themes';
import { setConfig } from '../utils/configUtils';
export function initialiseSuccess(config) {
  return {
    type: 'INITIALISATION_SUCCESS',
    payload: {
      config: config
    }
  };
}
export function initialiseFailure() {
  return {
    type: 'INITIALISATION_FAILURE'
  };
}
export function initialiseRequest() {
  return {
    type: 'INITIALISATION_REQUEST'
  };
}
export function initialise() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$configUrl = _ref.configUrl,
    configUrl = _ref$configUrl === void 0 ? '/api/config' : _ref$configUrl,
    _ref$theme = _ref.theme,
    theme = _ref$theme === void 0 ? themes.LIGHT : _ref$theme,
    _ref$allowThemeChange = _ref.allowThemeChange,
    allowThemeChange = _ref$allowThemeChange === void 0 ? false : _ref$allowThemeChange;
  return function (dispatch) {
    dispatch(setDefaultTheme(theme));
    dispatch(setAllowThemeChange(allowThemeChange));
    dispatch(initialiseRequest());
    return fetch(configUrl).then(function (response) {
      return response.json();
    }).then(function (json) {
      setConfig(json);
      dispatch(initialiseSuccess(json));
    }).catch(function (error) {
      dispatch(initialiseFailure(error));
    });
  };
}