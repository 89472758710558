/* eslint-disable no-bitwise */
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { getHexValue, getTextColor } from '../../utils/stringToHexValue';

function getInitials(name) {
  return name.split(' ').map(n => n[0]);
}

export default function InitialsAvatar({ name, style }) {
  const initials = getInitials(name);
  const backgroundColor = getHexValue(name);
  const textColor = getTextColor(backgroundColor);

  return <Avatar
    style={{
      ...style,
      backgroundColor,
      margin: 0,
      fontSize: 14,
      fontFamily: 'Josefin Sans',
      fontWeight: 600,
      fontStyle: 'normal',
      textTransform: 'uppercase',
      letterSpacing: '0.08em',
      color: textColor,
    }}
  >
    {initials}
  </Avatar>;
}

InitialsAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

InitialsAvatar.defaultProps = {
  style: {},
};
