import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function Logout({ iconColor }) {
  return (
    <g
      stroke={iconColor}
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15.793 15.793v4.931a1.896 1.896 0 0 1-1.896 1.897H3.276a1.896 1.896 0 0 1-1.897-1.897V3.276c0-1.048.85-1.897 1.897-1.897h10.62c1.048 0 1.897.85 1.897 1.897v4.93M10.483 11.62H22.62M18.828 8.207L22.62 12l-3.793 3.793" />
    </g>
  );
}

Logout.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(Logout);
