import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function Settings({ iconColor }) {
  return (
    <path
      d="M16.409 12.369a4.321 4.321 0 1 1-8.642 0 4.321 4.321 0 0 1 8.642 0zm3.996 1.14c.045-.364.08-.729.08-1.116a8.88 8.88 0 0 0-.08-1.117l2.386-1.879a.579.579 0 0 0 .136-.73l-2.262-3.941c-.136-.25-.44-.342-.69-.25l-2.816 1.139a8.224 8.224 0 0 0-1.91-1.117l-.43-3.02A.552.552 0 0 0 14.263 1H9.741a.554.554 0 0 0-.555.479l-.43 3.019a8.668 8.668 0 0 0-1.91 1.117L4.03 4.475a.55.55 0 0 0-.69.25L1.078 8.669a.564.564 0 0 0 .136.729l2.386 1.88c-.045.364-.079.74-.079 1.116 0 .376.034.752.079 1.116l-2.386 1.88a.577.577 0 0 0-.136.73L3.34 20.06c.136.25.441.342.69.25l2.816-1.139a8.285 8.285 0 0 0 1.91 1.117l.43 3.018a.554.554 0 0 0 .555.479h4.523c.283 0 .52-.205.554-.479l.43-3.018a8.694 8.694 0 0 0 1.911-1.117l2.816 1.14a.55.55 0 0 0 .69-.251l2.262-3.942a.579.579 0 0 0-.136-.73l-2.386-1.88z"
      stroke={iconColor}
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}

Settings.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(Settings);
