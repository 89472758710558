import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles, withTheme } from '@agora/agora-common';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

const styles = theme => ({
  button: {
    padding: 0,
    height: '24px !important',
    width: '24px',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.primary.main,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    color: theme.palette.primary.main,
  },
});

function VerticalExpandButton({ isExpanded, onClick, classes }) {
  return (
    <IconButton
      className={classNames(classes.button)}
      onClick={onClick}
      aria-expanded={isExpanded}
      aria-label="show more"
    >
      <ExpandMoreIcon
        className={clsx(classNames(classes.expand), {
          [classNames(classes.expandOpen)]: isExpanded,
        })}
      />
    </IconButton>
  );
}

VerticalExpandButton.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
    expand: PropTypes.string.isRequired,
    expandOpen: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(withTheme(VerticalExpandButton));
