import React from 'react';

export default function componentOfType(component) {
  const cName = component.name || component.displayName;
  return (props, propName, componentName) => {
    const p = props[propName];
    if (!React.isValidElement(p)) {
      return new Error(`not a valid \`${componentName}\` element`);
    }
    if (p !== undefined) {
      const pName = p.type.name || p.type.displayName;
      if (pName !== cName) {
        return new Error(
          `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Expected type of \`${cName}\` and received \`${pName}\`. Validation failed.`,
        );
      }
    }
    return null;
  };
}
