import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LoadingBar from 'react-redux-loading-bar';

const styles = theme => ({
  loadingBar: {
    backgroundColor: theme.palette.primary.main,
    zIndex: 99999,
    height: 3,
    position: 'absolute',
  },
});

export function HMLoadingBar({ classes }) {
  return (
    <LoadingBar className={classes.loadingBar} />
  );
}

HMLoadingBar.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};


export default withStyles(styles)(HMLoadingBar);
