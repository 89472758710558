import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import IconButton from './IconButton';

/**
 * Renders a hivemind icon button wrapped in a react-router link.
 * Children are hidden to make link accessible to screen readers.
 *
 * @param {string} to the route to link to
 */
export default function IconButtonLink({ to, ...rest }) {
  return (
    <IconButton
      component={React.forwardRef((props, ref) => <Link to={to} {...props} ref={ref} />)}
      {...rest}
    />
  );
}

IconButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
};

IconButtonLink.defaultProps = {
  children: null,
};
