import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function AlignLeft({ iconColor }) {
  return (
    <g fill={iconColor}>
      <path d="M15 15H3v2h12v-2zm0-8H3v2h12V7zM3 13h18v-2H3v2zm0 8h18v-2H3v2zM3 3v2h18V3H3z" />
    </g>
  );
}

AlignLeft.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(AlignLeft);
