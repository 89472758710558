import 'video.js/dist/video-js.min.css';
import './MarkdownVideoPlayer.css';
import { PLUGIN_NAME } from './VideoPluginConsts';
import validateToken from './validateToken';
import VideoElementCreatorFactory from './VideoElementCreatorFactory';
export default function MarkdownVideoPlugin(md) {
  var videoElementFactory = new VideoElementCreatorFactory(md.utils.escapeHtml);
  // eslint-disable-next-line no-param-reassign
  md.renderer.rules[PLUGIN_NAME] = videoElementFactory.create;
  md.inline.ruler.push(PLUGIN_NAME, validateToken);
}