/* eslint-disable no-bitwise */

function hashCode(str) {
  var hash = 0;
  for (var i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}
function intToRGBA(i) {
  var hex = (i >> 24 & 0xFF).toString(16) + (i >> 16 & 0xFF).toString(16) + (i >> 8 & 0xFF).toString(16) + (i & 0xFF).toString(16);
  hex += '000000';
  return hex.substring(0, 6);
}
export function hexToRgbA(hex) {
  var alpha = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x".concat(c.join(''));
    return "rgba(".concat([c >> 16 & 255, c >> 8 & 255, c & 255].join(','), ",").concat(alpha, ")");
  }
  throw new Error('Bad Hex');
}
export function getTextColor(hex) {
  var hexNumbers = hex.slice(1);
  var r = parseInt(hexNumbers.slice(0, 2), 16);
  var g = parseInt(hexNumbers.slice(2, 4), 16);
  var b = parseInt(hexNumbers.slice(4, 6), 16);
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? 'rgba(23, 26, 29, 0.7)' : '#FFFFFF';
}
export function getHexValue(name) {
  return "#".concat(intToRGBA(hashCode(name)));
}