import React, { useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Grid } from '@material-ui/core';
import ImageViewerToolbar from './ImageViewerToolbar';

const minScale = 1;
const maxScale = 8;
const step = 20;

export default function ImageViewer({ src, alt }) {
  const [wheelDisabled, setWheelDisabled] = useState(true);
  const [zoomLevel, setZoomLevel] = useState(1);

  const debouncedSetZoomLevel = debounce(setZoomLevel, 250);

  const [imageLoaded, setImageLoaded] = useState(false);

  const [toolbarMaxWidth, setToolbarMaxWidth] = useState('100%');

  const image = useRef();
  useLayoutEffect(() => {
    if (imageLoaded && image.current) {
      const imageWidth = image.current.clientWidth;
      setToolbarMaxWidth(imageWidth >= 300 ? imageWidth : 300);
    }
  }, [imageLoaded]);

  return <TransformWrapper
    defaultScale={1}
    wheel={{ disabled: wheelDisabled }}
    onZoomChange={({ scale }) => {
      debouncedSetZoomLevel(scale);
    }}
    zoomIn={{ step }}
    zoomOut={{ step }}
    doubleClick={{ step }}
    options={{ maxScale, minScale }}
  >

    {({ zoomIn, zoomOut, resetTransform }) => (
      <Grid direction="column" container>
        <Grid
          item
          xs={12}
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 2,
            maxWidth: toolbarMaxWidth,
          }}
        >
          <ImageViewerToolbar
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            canZoomIn={zoomLevel < maxScale}
            canZoomOut={zoomLevel > minScale}
            reset={resetTransform}
            canReset={zoomLevel > minScale}
            wheelDisabled={wheelDisabled}
            setWheelDisabled={setWheelDisabled}
            imageSrc={src}
          />
        </Grid>
        <Grid item xs={12}>
          <TransformComponent>
            <img
              ref={image}
              src={src}
              alt={alt}
              onLoad={() => setImageLoaded(true)}
            />
          </TransformComponent>
        </Grid>

      </Grid>
    )}
  </TransformWrapper>;
}

ImageViewer.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

ImageViewer.defaultProps = {
  alt: null,
};
