import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

export function ShowChartIcon({ iconColor }) {
  return (
    <g
      fill={iconColor}
      stroke="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z" />
    </g>
  );
}

ShowChartIcon.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(ShowChartIcon);
