import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function Dashboard({ iconColor }) {
  return (
    <g>
      <path
        d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"
        fill={iconColor}
        stroke={iconColor}
        fillRule="evenodd"
      />
    </g>
  );
}

Dashboard.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(Dashboard, 'hm-fill-icon');
