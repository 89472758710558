import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function ResumeIcon({ iconColor }) {
  return (
    <g
      stroke={iconColor}
      strokeWidth="1.5"
      fill={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M8 5v14l11-7z" />
    </g>
  );
}

ResumeIcon.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(ResumeIcon);
