import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import videoJs from 'video.js';
import markdownConvertorFactory from './MarkdownConvertorFactory';
import usePlaceholder from './hooks/usePlaceholder';
import { VideoRenderer } from './VideoPlugin';
import { ImageRenderer } from './ImageRendererRule';
import './markdown-renderer-pane.css';

export default function MarkdownRendererPane({ markdown }) {
  const markdownConvertor = useRef(markdownConvertorFactory());

  const markdownRef = useRef();

  const [imagePlaceholders] = usePlaceholder(markdownRef, markdown, 'hm-image-zoom');
  const [videoPlaceholders] = usePlaceholder(markdownRef, markdown, 'hm-video-js', videoElement => videoJs(videoElement).dispose());

  // only regenerate markup when markdown changes
  const markdownHtml = useMemo(() => markdownConvertor.current.render(markdown || ''), [markdown]);

  return (
  // We have to render markdown as html, markdown-it provides some safety around this.
  // https://github.com/markdown-it/markdown-it/blob/master/docs/security.md
    <>
      <div
        ref={markdownRef}
        className="hm-markdown"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: markdownHtml }}
      />
      <ImageRenderer placeholders={imagePlaceholders}/>
      <VideoRenderer placeholders={videoPlaceholders}/>
    </>
  );
}

MarkdownRendererPane.propTypes = {
  markdown: PropTypes.string,
};

MarkdownRendererPane.defaultProps = {
  markdown: '',
};
