import { combineReducers } from 'redux';
import { snackbar, formConfig, theme } from '@agora/agora-common/reducers';
import views from './Views/reducer';
import entities from './Entities/reducer';

const rootReducer = combineReducers({
  snackbar,
  formConfig,
  theme,
  views,
  entities,
});

export default rootReducer;
