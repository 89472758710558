import Icons from './Icons';
export { default as Clock } from './Clock';
export { default as Home } from './Home';
export { default as Logout } from './Logout';
export { default as Settings } from './Settings';
export { default as Stats } from './Stats';
export { default as Dashboard } from './Dashboard';
export { default as Pause } from './Pause';
export { default as Resume } from './Resume';
export { default as ChevronRight } from './ChevronRight';
export { default as ChevronLeft } from './ChevronLeft';
export { default as Approve } from './Approve';
export { default as Reject } from './Reject';
export { default as Remove } from './Remove';
export { default as Tasks } from './Tasks';
export { default as Person } from './Person';
export { default as UserStats } from './UserStats';
export { default as Save } from './Save';
export { default as Cancel } from './Cancel';
export { default as AlignLeft } from './AlignLeft';
export { default as OpenInNewWindow } from './OpenInNewWindow';
export { default as Edit } from './Edit';
export { default as MoreHorizontal } from './MoreHorizontal';
export { default as ShowChart } from './ShowChart';
export { default as FileDownload } from './FileDownload';
export { default as Qualifications } from './Qualifications';
export { default as Chaining } from './Chaining';
export { default as Files } from './Files';
export default Icons;