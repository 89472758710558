import { useRef, useEffect } from 'react';
import { browserHistory } from 'react-router';
export default function useRedirectIfLoggedIn(isAuthenticated, isAuthenticating, redirectTo, verifyLogin) {
  var isNewLogin = useRef(false);
  useEffect(function () {
    isNewLogin.current = isNewLogin.current || isAuthenticating;
    if (!isNewLogin.current) {
      if (!isAuthenticated) {
        verifyLogin();
      }
      if (isAuthenticated) {
        browserHistory.replace(redirectTo);
      }
    }
  }, [isAuthenticated, isAuthenticating, redirectTo, verifyLogin]);
}