import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import FormHolder from '../FormHolder/FormHolder';
import useRedirectIfLoggedIn from '../utils/hooks/useRedirectIfLoggedIn';
import LoginFormContainer from './LoginFormContainer';

export default function LoginPage({
  subText,
  title,
  location,
  claim,
  handleOnLoginAttempt,
  isAuthenticated,
  isAuthenticating,
  verifyLogin,
}) {
  const handleForgotPassword = () => {
    browserHistory.push('forgot-password');
  };

  const redirectTo = !location.query.redirect ? '/' : location.query.redirect;
  useRedirectIfLoggedIn(isAuthenticated, isAuthenticating, redirectTo, verifyLogin);

  return (
    <FormHolder subText={subText}>
      <div>
        <div>
          <h1>{title}</h1>
          <LoginFormContainer
            location={location}
            claim={claim}
            onForgotPassword={handleForgotPassword}
            onLoginAttemptComplete={handleOnLoginAttempt}
          />
        </div>
      </div>
    </FormHolder>
  );
}

LoginPage.propTypes = {
  title: PropTypes.string.isRequired,
  subText: PropTypes.string,
  location: PropTypes.shape({}).isRequired,
  claim: PropTypes.string.isRequired,
  handleOnLoginAttempt: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  verifyLogin: PropTypes.func.isRequired,
};

LoginPage.defaultProps = {
  handleOnLoginAttempt: null,
  redirect: '/',
  subText: 'PLATFORM',
};
