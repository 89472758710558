import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import MUIDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Close from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import Grid from '@material-ui/core/Grid';
import Button from '../../Button';
import DefaultButtonPropTypes from '../../Button/DefaultButtonPropTypes';
import IconButton from '../../IconButton';
import componentOfType from '../../utils/componentOfType';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const StyledDialogContent = withStyles({
  root: {
    margin: 0,
    padding: 0,
  },
})(DialogContent);

const StyledDialogActions = withStyles({
  root: {
    margin: 0,
    padding: '24px',
  },
})(DialogActions);

const styles = theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.section,
    border: `1px solid ${blueGrey[700]}`,
  },
  container: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    padding: '0 24px',
  },
  content: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  closeButtonRoot: {
    right: 0,
    top: 0,
    position: 'absolute',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  closeButtonIcon: {
    fill: theme.palette.primary.main,
  },
});

function Dialog({
  children,
  buttons,
  classes,
  closeOnBackdropClick,
  showCloseButton,
  draggable,
  isOpen,
  onClose,
  maxWidth,
  className,
  title,
}) {
  let internalDraggable = true;

  const handleBackdropClick = (_, reason) => {
    if (closeOnBackdropClick && reason === 'backdropClick') {
      onClose();
    }
  };

  function PaperComponent(props) {
    // Note: dragging events are disabled with click within the content area via internalDraggle
    return (
      <Draggable
        disabled={!draggable}
        onStart={() => internalDraggable}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  return (
    <MUIDialog
      classes={{ paper: classes.root }}
      open={isOpen}
      onClose={handleBackdropClick}
      maxWidth={maxWidth}
      fullWidth
      PaperComponent={draggable ? PaperComponent : undefined}
      TransitionComponent={Transition}
    >
      {showCloseButton && (
        <IconButton
          Icon={Close}
          classes={{
            root: classes.closeButtonRoot,
            icon: classes.closeButtonIcon,
          }}
          color="primary"
          size="medium"
          onClick={onClose}
          accessibilityLabel="Close"
        />
      )}
      {title && <DialogTitle>{title}</DialogTitle>}
      <StyledDialogContent>
        <div
          className={classNames(className, classes.container)}
          onMouseEnter={() => {
            internalDraggable = false;
          }}
          onMouseLeave={() => {
            internalDraggable = true;
          }}
        >
          <div style={{ width: '100%' }} className={classNames(classes.content)}>
            {children}
          </div>
        </div>
      </StyledDialogContent>
      {buttons && (
        <StyledDialogActions>
          <Grid container spacing={2} justifyContent="flex-end">
            {buttons.map((button) => {
              if (React.isValidElement(button)) {
                return (
                  <Grid item key={button.key}>
                    {button}
                  </Grid>
                );
              }
              return (
                <Grid item key={button.text}>
                  <Button
                    Icon={button.icon}
                    color={button.color || 'secondary'}
                    size={button.size}
                    fullWidth={button.fullWidth}
                    disabled={button.disabled}
                    onClick={button.onClick}
                    variant="contained"
                    className={classNames(classes.button)}
                  >
                    {button.text}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </StyledDialogActions>
      )}
    </MUIDialog>
  );
}

Dialog.propTypes = {
  buttons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      ...DefaultButtonPropTypes,
      text: PropTypes.string,
      onClick: PropTypes.func,
    })),
    PropTypes.arrayOf(componentOfType(Button)),
  ]),
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({}).isRequired,
  closeOnBackdropClick: PropTypes.bool,
  draggable: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  onClose: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.node,
};

Dialog.defaultProps = {
  buttons: null,
  closeOnBackdropClick: true,
  draggable: false,
  maxWidth: 'sm',
  className: '',
  title: null,
  showCloseButton: true,
};

export default withStyles(styles, { withTheme: true })(Dialog);
