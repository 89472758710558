import React from 'react';
import moduleLoadingTypes from '../moduleLoadingTypes';

export default function loadingPage(WrappedModuleLoadingBase) {
  const wrappedForm =
    function LoadingPage(props) {
      const newProps = {
        ...props,
        minHeight: '100vh',
        type: moduleLoadingTypes.PAGE,
        setBackgroundColor: true,
      };

      return <WrappedModuleLoadingBase {...newProps} />;
    };

  return wrappedForm;
}
