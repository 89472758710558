export { default as authFetch } from './authFetch';
export { default as cancellableAuthFetch, handleCancellationError } from './cancellableAuthFetch';
export { isValidToken, userHasClaim, refreshAuthIfNeeded, refreshAuth } from './authUtils';
export { default as deleteProperty } from './deleteProperty';
export { getPersistedThemeState, setupThemePersistance } from './themePersistor';
export { default as containsFunction } from './containsFunction';
export { default as throttle } from './throttle';

/**
 * @param {string} url
 * @param {{type?: 'hidden', name: string, value: string}[]|undefined} params
 * @returns {HTMLFormElement} Ready to submit
 */
export var createFormRequest = function createFormRequest(url, params) {
  var form = document.createElement('form');
  form.method = 'POST';
  form.action = url;
  (params !== null && params !== void 0 ? params : []).forEach(function (_ref) {
    var type = _ref.type,
      name = _ref.name,
      value = _ref.value;
    var input = document.createElement('input');
    input.type = type !== null && type !== void 0 ? type : 'hidden';
    input.name = name;
    input.value = value;
    form.appendChild(input);
  });
  document.body.appendChild(form);
  return form;
};