import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from './LoadingIndicator';

export default function LoadingPanel({ message, isLoading, children }) {
  if (isLoading) {
    return (<LoadingIndicator message={message} />);
  }
  return children;
}

LoadingPanel.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node,
  message: PropTypes.string,
};

LoadingPanel.defaultProps = {
  message: '',
  children: null,
};
