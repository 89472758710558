import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import StatsIcon from '@material-ui/icons/ShowChart';
import Tooltip from '@material-ui/core/Tooltip';
import { trackEvent } from '../../Analytics';

const styles = {
  button: {
    padding: 0,
    width: 48,
    height: 48,
    label: { height: 48 },
  },
};

function StatsBottomSheetButton({ toggleBottomSheet, bottomSheetIcon, classes, label }) {
  return (
    <Tooltip
      title={label}
      placement="right"
    >
      <div>
        <IconButton
          style={{ backgroundColor: 'transparent' }}
          onClick={() => {
            toggleBottomSheet();
            trackEvent({ event: 'Toggled Stats Panel' });
          }}
          aria-label="More"
          className={classes.button}
        >
          {bottomSheetIcon || <StatsIcon />}
        </IconButton>
        <span className="hm-nav-title">{label}</span>
      </div>
    </Tooltip>);
}

StatsBottomSheetButton.propTypes = {
  toggleBottomSheet: PropTypes.func,
  label: PropTypes.string,
  bottomSheetIcon: PropTypes.node,
  classes: PropTypes.shape({}).isRequired,
};

StatsBottomSheetButton.defaultProps = {
  toggleBottomSheet: null,
  label: 'Statistics',
  bottomSheetIcon: null,
};


export default withStyles(styles)(StatsBottomSheetButton);
