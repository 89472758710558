import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

export function StatsIcon({ iconColor }) {
  return (
    <g
      stroke={iconColor}
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 1.38V12h10.241M12 12l7.207 7.207" />
      <path d="M22.62 12c0 5.866-4.754 10.62-10.62 10.62S1.38 17.867 1.38 12 6.133 1.38 12 1.38 22.62 6.133 22.62 12z" />
    </g>
  );
}

StatsIcon.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(StatsIcon);
