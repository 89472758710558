import { connect } from 'react-redux';
import { ssoLoginEnterprise } from '../../actions/authActions';
import SSOLoginForm from './SSOLoginForm';
function mapDispatchToProps(dispatch) {
  return {
    login: function login(username, useRefreshToken) {
      return dispatch(ssoLoginEnterprise(username, useRefreshToken));
    }
  };
}
function mapStateToProps(state) {
  return {
    isAuthenticating: state.auth.isAuthenticating
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SSOLoginForm);