import React from 'react';

export default function NotFoundPage() {
  return (
    <div style={{ height: '100vh' }} className="row center-xs middle-xs">
      <div>
        <h1 className="col-xs-12">Four Zero Four</h1>
        <p className="col-xs-12">We can&#39;t seem to find the page you&#39;re looking for.</p>
        <p className="col-xs-12">Error code: 404</p>
      </div>
    </div>
  );
}
