import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import HivemindLoginForm from './HivemindLoginForm';
function mapDispatchToProps(dispatch, ownProps) {
  return {
    login: function login(username, password, redirect, useRefreshToken) {
      dispatch(loginUser(username, password, ownProps.claim, redirect, useRefreshToken)).then(function () {
        if (ownProps.onLoginAttemptComplete) {
          ownProps.onLoginAttemptComplete();
        }
      });
    }
  };
}
function mapStateToProps(state, ownProps) {
  return {
    isAuthenticating: state.auth.isAuthenticating,
    redirect: ownProps.location.query.redirect || '/'
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(HivemindLoginForm);