function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
/* eslint-disable no-console */
import { analyticsEnabled } from '../utils/initAnalytics';

/**
 * Associate users and their actions to a recognizable user id with optional traits
 *
 * @param {?String} userId
 * @param {?Object} traits
 * @param {?Object} options
 * @param {?function} callback
 * @see {@link https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify}
 */
export default function createIdentity(_ref) {
  var userId = _ref.userId,
    traits = _ref.traits,
    options = _ref.options,
    callback = _ref.callback;
  if (analyticsEnabled()) {
    try {
      window.analytics.identify(userId, traits, _objectSpread(_objectSpread({}, options), {}, {
        context: {
          ip: '0.0.0.0'
        }
      }), callback);
    } catch (e) {
      console.warn('Analytics createIdentity failed');
    }
  }
}