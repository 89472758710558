import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'rc-scrollbars';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { withStyles, withTheme } from '@agora/agora-common';
import TextField from '@material-ui/core/TextField';

import Button from '@agora/agora-common/Button';

import * as colours from '../../../../styles/colours';
import ContentHolder from '../../Common/ContentHolder';
import FormSection from '../../Common/FormSection';
import GroupBox from '../../Common/GroupBox';
import SocialPreferences from '../../Profile/SocialPreferences';

const styles = () => ({
  container: {
    backgroundColor: colours.pageBackground,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  content: {
    height: '100%',
  },
  textField: {
    margin: '1em 0',
  },
});

function InviteNamePasswordForm({
  createAccount,
  classes,
  isChangingPassword,
  verifyReset,
}) {
  const [name, setName] = useState('');
  const [realName, setRealName] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [nameError, setNameError] = useState('');
  const [password1Error, setPassword1Error] = useState('');
  const [password2Error, setPassword2Error] = useState('');

  const [inviteToOtherMarkets, setInviteToOtherMarkets] = useState(false);
  const [gitHubUrl, setGitHubUrl] = useState('');
  const [googleScholarUrl, setGoogleScholarUrl] = useState('');
  const [linkedInUrl, setLinkedInUrl] = useState('');
  const [other, setOther] = useState('');
  const [gitHubUrlErrorText, setGitHubUrlErrorText] = useState('');
  const [googleScholarUrlErrorText, setGoogleScholarUrlErrorText] = useState('');
  const [linkedInUrlErrorText, setLinkedInUrlErrorText] = useState('');
  const [otherErrorText, setOtherErrorText] = useState('');

  const [mailingList, setMailingList] = useState(false);
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);
  const [termsAndConditionsError, setTermsAndConditionsError] = useState('');

  useEffect(() => {
    verifyReset();
  },
  []);

  const validate = () => {
    const maxUrlLength = 2048;
    let valid = true;

    if (name) {
      setNameError('');
    } else {
      setNameError('Name cannot be blank');
      valid = false;
    }

    if (!password1 || password1.length < 8) {
      setPassword1Error('Passwords must be 8 characters or longer');
      valid = false;
    } else {
      setPassword1Error('');
    }

    if (password1 !== password2) {
      setPassword1Error('Passwords do not match');
      setPassword2Error('Passwords do not match');
      valid = false;
    } else {
      setPassword2Error('');
    }

    if (gitHubUrl && gitHubUrl.length > maxUrlLength) {
      setGitHubUrlErrorText(`Url must be ${maxUrlLength} characters or less`);
      valid = false;
    } else {
      setGitHubUrlErrorText('');
    }

    if (googleScholarUrl && googleScholarUrl.length > maxUrlLength) {
      setGoogleScholarUrlErrorText(`Url must be ${maxUrlLength} characters or less`);
      valid = false;
    } else {
      setGoogleScholarUrlErrorText('');
    }

    if (linkedInUrl && linkedInUrl.length > maxUrlLength) {
      setLinkedInUrlErrorText(`Url must be ${maxUrlLength} characters or less`);
      valid = false;
    } else {
      setLinkedInUrlErrorText('');
    }

    if (other && other.length > 4096) {
      setOtherErrorText('Text must be 4096 characters or less');
      valid = false;
    } else {
      setOtherErrorText('');
    }

    if (acceptTermsAndConditions) {
      setTermsAndConditionsError('');
    } else {
      setTermsAndConditionsError('You must accept the terms and conditions to create an account.');
      valid = false;
    }

    return valid;
  };

  return (
    <ContentHolder>
      <Scrollbars
        autoHide
        style={{ width: '100%' }}
      >
        <Grid container alignItems="center" justifyContent="center" classes={{ container: classes.content }}>
          <Grid item xs={11} md={4}>
            <h1>Welcome to AGORA</h1>
            <FormSection title="Thanks for registering. Fill in the details below to start playing.">
              <form style={{ paddingTop: '1em' }}>
                <FormSection>
                  Your display name will be shown to other players on market leaderboards. The real name field will be used by us
                  if we contact you directly, for example, inviting you to play in future markets.
                  <TextField
                    label="Display Name"
                    type="text"
                    value={name}
                    fullWidth
                    error={!!nameError}
                    helperText={nameError}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                  />

                  <TextField
                    label="Real name (hidden from other users)"
                    type="text"
                    value={realName}
                    fullWidth
                    onChange={(event) => {
                      setRealName(event.target.value);
                    }}
                  />

                  <TextField
                    label="New password"
                    type="password"
                    value={password1}
                    fullWidth
                    error={!!password1Error}
                    helperText={password1Error}
                    onChange={(event) => {
                      setPassword1(event.target.value);
                    }}
                  />

                  <TextField
                    disabled={isChangingPassword}
                    label="Confirm password"
                    type="password"
                    value={password2}
                    fullWidth
                    error={!!password2Error}
                    helperText={password2Error}
                    onChange={(event) => {
                      setPassword2(event.target.value);
                    }}
                  />
                </FormSection>

                <SocialPreferences
                  gitHubUrlErrorText={gitHubUrlErrorText}
                  googleScholarUrlErrorText={googleScholarUrlErrorText}
                  linkedInUrlErrorText={linkedInUrlErrorText}
                  otherErrorText={otherErrorText}
                  onInviteToOtherMarketsChanged={val => setInviteToOtherMarkets(val)}
                  onGithubUrlChanged={val => setGitHubUrl(val)}
                  onGoogleScholarUrlChanged={val => setGoogleScholarUrl(val)}
                  onLinkedInUrlChanged={val => setLinkedInUrl(val)}
                  onOtherChanged={val => setOther(val)}
                  isSaving={isChangingPassword}
                />

                <FormSection>
                  <GroupBox title="Keeping in touch">
                    <FormControlLabel
                      control={(
                        <Checkbox
                          onChange={(event) => { setMailingList(event.target.checked); }}
                          color="primary"
                        />
)}
                      label={(
                        <div>
                          {
                          'Yes, I\'d like to be among the first to hear your latest news, and would be happy to receive updates from CRUCIAL. '
                          + 'I understand that I can opt out at any time, and that your privacy policy can be viewed '
                        }
                          <Link href="https://www.hvmd.io/privacy-notice" target="_blank">here</Link>
                          .
                        </div>
                    )}
                    />
                  </GroupBox>
                </FormSection>

                <FormSection>
                  <GroupBox
                    title="Terms and conditions"
                    error={!!termsAndConditionsError}
                    helperText={termsAndConditionsError}
                  >
                    <FormControlLabel
                      control={(
                        <Checkbox
                          defaultChecked={acceptTermsAndConditions}
                          onChange={event => setAcceptTermsAndConditions(event.target.checked)}
                          color="primary"
                        />
)}
                      label={(
                        <div>
                          {'I accept the '}
                          <Link href="https://www.hvmd.io/agora-terms-of-use" target="_blank">terms and conditions</Link>
                          {' '}
                          for AGORA.
                        </div>
)}
                    />
                  </GroupBox>
                </FormSection>

                <div style={{ paddingTop: '1em' }}>
                  <Button
                    type="submit"
                    fullWidth
                    onClick={(e) => {
                      e.preventDefault();

                      if (validate()) {
                        createAccount({
                          name,
                          realName,
                          password: password1,
                          gitHubUrl,
                          googleScholarUrl,
                          inviteToOtherMarkets,
                          linkedInUrl,
                          mailingList,
                          other,
                        });
                      }
                    }}
                  >
                    Change
                  </Button>
                </div>
              </form>
            </FormSection>
          </Grid>
        </Grid>
      </Scrollbars>
    </ContentHolder>
  );
}

InviteNamePasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
  createAccount: PropTypes.func.isRequired,
  isChangingPassword: PropTypes.bool.isRequired,
  verifyReset: PropTypes.func.isRequired,
};

export default withStyles(styles)(withTheme(InviteNamePasswordForm));
