import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withTheme } from '@material-ui/core/styles';
import { deleteProperty } from '../../utils';

export default function styleIcon(WrappedIcon, additionalClassName) {
  function StyledIcon(props) {
    return (
      <SvgIcon
        {...deleteProperty(props, 'theme')}
        className={`${props.className}${additionalClassName ? ` ${additionalClassName}` : ''}`}
      >
        {
          <WrappedIcon iconColor={props.theme.palette.text.primary}/>
        }
      </SvgIcon>
    );
  }

  StyledIcon.propTypes = {
    theme: PropTypes.shape({
      palette: PropTypes.shape({
        text: PropTypes.shape({
          primary: PropTypes.string,
        }),
      }),
    }).isRequired,
    className: PropTypes.string,
  };

  StyledIcon.defaultProps = {
    className: '',
  };

  return withTheme(StyledIcon);
}
