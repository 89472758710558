import React from 'react';
import './sidenav.css';

export default function InnerSideNavHeader() {
  return (
    <div
      className="hm-aligner hm-sidenav-header"
      style={{
        height: 72, position: 'absolute', top: 0, width: '100%',
      }}
    >
      <div className="hm-aligner-item" style={{ textAlign: 'center' }}>
        <img src="/crucial-mini.svg" height={50} alt="Logo" />
      </div>
    </div>
  );
}
