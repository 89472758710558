import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@agora/agora-common';
import Button from '@agora/agora-common/Button';
import Dialog from '@agora/agora-common/Dialog/Dialog';
import TinyLoadingIndicator from '../../../Common/TinyLoadingIndicator/TinyLoadingIndicator';

const styles = {
  spinnerOffset: {
    display: 'inline',
    position: 'absolute',
    left: '-35px',
  },
};

function ConfirmationDialog({
  buttonColor,
  confirmText,
  confirmEnabled,
  children,
  isInProgress,
  isOpen,
  title,
  onConfirm,
  onCancel,
  classes,
}) {
  const buttons = [
    <Button
      color={buttonColor}
      disabled={!confirmEnabled}
      onClick={onConfirm}
      style={{ minWidth: '8em' }}
    >
      <div>
        {isInProgress || confirmText}
        {isInProgress && <TinyLoadingIndicator message="Please wait..." classes={{ spinnerOffset: classes.spinnerOffset }} />}
      </div>
    </Button>,
  ];

  return (
    <Dialog isOpen={isOpen} onClose={onCancel} title={title} buttons={buttons}>
      {children}
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  buttonColor: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    buttons: PropTypes.string,
    spinnerOffset: PropTypes.string,
  }).isRequired,
  confirmEnabled: PropTypes.bool,
  confirmText: PropTypes.string,
  isInProgress: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  buttonColor: 'primary',
  children: '',
  confirmEnabled: true,
  confirmText: 'OK',
  isInProgress: false,
  onConfirm: () => {},
  onCancel: () => {},
  title: 'Confirmation',
};

export default withStyles(styles)(ConfirmationDialog);
