import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function ChevronLeft({ iconColor }) {
  return (
    <g
      stroke={iconColor}
      strokeWidth="1.5"
      fill={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
    </g>
  );
}

ChevronLeft.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(ChevronLeft, 'hm-fill-icon');
