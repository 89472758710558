/* eslint-disable react/jsx-filename-extension */
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, browserHistory, Route, IndexRedirect } from 'react-router';

import {
  App,
  ChangePassword,
  ForgotPassword,
  ThemeProvider,
  LoadingBar,
} from '@agora/agora-common';
import { NotFoundPage } from '@agora/agora-common/Pages';
import requireAuthentication from '@agora/agora-common/Auth';
import { initActions, menuItemActions } from '@agora/agora-common/actions';
import Icons from '@agora/agora-common/Icons';
import Themes from '@agora/agora-common/styles/Themes';
import { connectPageTracking } from '@agora/agora-common/Analytics';

import store from './store';
import './styles';

import { setHasClaim } from './actions/Auth/Claims/claimsActions';
import claimTypes from './actions/Auth/Claims/claimTypes';
import { fetchPermissions } from './actions/Permissions/actions';
import { fetchEmailVerified } from './actions/Profile/actions';

import Views from './components/Views';
import Login from './components/Views/Login';
import VerifyEmail from './components/Views/VerifyEmail';
import InviteNamePasswordForm from './components/Views/Invitation/InviteNamePasswordForm';
import PublicPrices from './components/Views/PublicPrices';
import { Pages } from './analytics/pages/consts/pageTypes';

// delay render until init has complete
store
  .dispatch(
    initActions.initialise({ theme: Themes.DARK, allowThemeChange: false }),
  )
  .then(() => {
    ReactDOM.render(
      <Provider store={store}>
        <ThemeProvider>
          <>
            <LoadingBar />
            <Router history={browserHistory}>
              <Route
                path="login"
                component={connectPageTracking(
                  ({ location }) => (
                    <Login title="Login" location={location} subText="AGORA" />
                  ),
                  { name: Pages.LOGIN },
                )}
              />
              <Route
                path="verify-email"
                component={({ location }) => (
                  <VerifyEmail location={location} subText="AGORA" />
                )}
              />
              <Route
                path="forgot-password"
                component={connectPageTracking(
                  ({ location }) => (
                    <ForgotPassword location={location} subText="AGORA" />
                  ),
                  { name: Pages.FORGOT_PASSWORD },
                )}
              />
              <Route
                path="change-password"
                component={connectPageTracking(
                  ({ location }) => (
                    <ChangePassword location={location} subText="AGORA" />
                  ),
                  { name: Pages.CHANGE_PASSWORD },
                )}
              />
              <Route
                path="invitation-password"
                component={connectPageTracking(
                  ({ location }) => (
                    <InviteNamePasswordForm location={location} />
                  ),
                  { name: Pages.INVITED_SIGN_UP },
                )}
              />

              <Route
                path="prices/:organisationId/:marketId"
                component={connectPageTracking(PublicPrices, {
                  name: Pages.PUBLIC_PRICES,
                })}
              />

              <Route path="/" component={App}>
                <IndexRedirect to="markets" />
                <Route
                  path="markets(/:marketId)"
                  component={connectPageTracking(
                    requireAuthentication(Views.Market),
                    { name: Pages.MARKET },
                  )}
                />
                <Route
                  path="accounts"
                  component={connectPageTracking(
                    requireAuthentication(Views.Accounts),
                    { name: Pages.ACCOUNT },
                  )}
                />
                <Route
                  path="manage"
                  component={connectPageTracking(
                    requireAuthentication(Views.Control),
                    { name: Pages.MANAGE },
                  )}
                />
                <Route
                  path="admin"
                  component={connectPageTracking(
                    requireAuthentication(Views.Admin),
                    { name: Pages.ADMIN },
                  )}
                />
                <Route
                  path="profile"
                  component={connectPageTracking(
                    requireAuthentication(Views.Profile),
                    { name: Pages.PROFILE },
                  )}
                />
                <Route
                  path="*"
                  component={connectPageTracking(NotFoundPage, {
                    name: Pages.NOT_FOUND,
                  })}
                />
              </Route>
            </Router>
          </>
        </ThemeProvider>
      </Provider>,
      document.getElementById('root'),
    );
  });

// Register menu items on a successful login
let isAuthenticated;
let isLoadingPermissions = false;
let loadedMenu = false;

store.subscribe(() => {
  const state = store.getState();
  // eslint-disable-next-line prefer-destructuring
  isAuthenticated = state.auth.isAuthenticated;

  if (loadedMenu) {
    loadedMenu &= isAuthenticated;
    return;
  }

  if (isAuthenticated && !isLoadingPermissions && !state.permissions.loaded) {
    // Begin loading permissions once we know the user is authenticated
    isLoadingPermissions = true;
    store
      .dispatch(fetchEmailVerified())
      .then(() => store.dispatch(fetchPermissions()));
  }

  if (isAuthenticated && state.permissions.loaded) {
    // Wait until permissions have been retrieved before loading menu options
    loadedMenu = true;
    isLoadingPermissions = false;
    store.dispatch(setHasClaim(claimTypes.ROLE_ORGADMIN));
    store.dispatch(setHasClaim(claimTypes.ROLE_OPERATOR));
    store.dispatch(setHasClaim(claimTypes.ROLE_BASIC));

    // const state = store.getState();

    if (state.profile.verified) {
      // All users can see Markets/Accounts tab
      store.dispatch(
        menuItemActions.registerMenuItem(
          Icons.DASHBOARD,
          '/markets',
          'Markets',
        ),
      );

      store.dispatch(
        menuItemActions.registerMenuItem(
          Icons.USER_STATS,
          '/accounts',
          'Accounts',
        ),
      );
    } else {
      // Users must verify their email address before they can use the app. The profile dialog will
      // prompt them about email verification and allow them to update their email or resend verification link.
      browserHistory.push('/profile');
    }

    store.dispatch(
      menuItemActions.registerMenuItem(Icons.PERSON, '/profile', 'Profile'),
    );

    // Hide the button for the old admin UI. We're leaving it there as an option to use if the path is known but won't be offering a link to it.
    // if (state.claims.byName[claimTypes.CLAIM_AGORA_ADMIN_NAME]
    //   || state.claims.byName[claimTypes.ROLE_OPERATOR].length
    //   || state.claims.byName[claimTypes.ROLE_ORGADMIN].length) {
    //   // Operators can see the Admin tab
    //   store.dispatch(menuItemActions.registerMenuItem(Icons.TASKS, '/admin', 'Admin'));
    // }
  }
});
