import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import themes from '../../styles/Themes';
import NestedMenuItem from '../../NestedMenuItem';

export default function ThemeMenu({ show, onSetTheme, onClick }) {
  const selectTheme = theme => () => {
    onSetTheme(theme);
    onClick();
  };

  if (show) {
    return <NestedMenuItem label="Theme" >
      <MenuItem
        key={themes.LIGHT}
        onClick={selectTheme(themes.LIGHT)}
      >
        Light
      </MenuItem>
      <MenuItem
        key={themes.DARK_BLUE}
        onClick={selectTheme(themes.DARK_BLUE)}
      >
        Dark (Blue)
      </MenuItem>
      <MenuItem
        key={themes.DARK}
        onClick={selectTheme(themes.DARK)}
      >
        Dark (Yellow)
      </MenuItem>
    </NestedMenuItem>;
  }
  return null;
}

ThemeMenu.propTypes = {
  show: PropTypes.bool,
  onSetTheme: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

ThemeMenu.defaultProps = {
  show: false,
  onClick: () => {},
};
