import Markdown from 'markdown-it';
import hljs from 'highlight.js';
import json from 'highlight.js/lib/languages/json';
import './code-highlighting.css';
import MarkdownVideoPlugin from './VideoPlugin';
import ColoredTextPlugin from './ColouredTextPlugin';
import FontFamilyTextPlugin from './FontFamilyPlugin';
import ImageRendererRule from './ImageRendererRule';
hljs.registerLanguage('json', json);
function codeHighlighting(str, lang) {
  if (lang && hljs.getLanguage(lang)) {
    try {
      return "<pre class=\"hljs\"><code>".concat(hljs.highlight(lang, str, true).value, "</code></pre>");
    } catch (__) {// do nothing
    }
  }
  return "<pre class=\"hljs\"><code>".concat(Markdown().utils.escapeHtml(str), "</code></pre>");
}
function addAttributeToToken(attrName, value, token) {
  var aIndex = token.attrIndex(attrName);
  if (aIndex < 0) {
    token.attrPush([attrName, value]);
  } else {
    // eslint-disable-next-line no-param-reassign
    token.attrs[aIndex][1] = value;
  }
}
export default function MarkdownConvertorFactory() {
  var markdownConvertor = new Markdown({
    html: false,
    linkify: true,
    highlight: codeHighlighting
  });
  markdownConvertor.use(MarkdownVideoPlugin);
  markdownConvertor.use(FontFamilyTextPlugin);
  markdownConvertor.use(ColoredTextPlugin);

  // The following is taken from the markdown-it docs to open links in a new tab
  // ( add target="_blank" to all links):
  var defaultLinkRender = markdownConvertor.renderer.rules.link_open || function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
  };
  markdownConvertor.renderer.rules.link_open = function (tokens, idx, options, env, self) {
    addAttributeToToken('target', '_blank', tokens[idx]);
    addAttributeToToken('rel', 'noopener noreferrer', tokens[idx]);
    return defaultLinkRender(tokens, idx, options, env, self);
  };

  // images with zoom
  markdownConvertor.renderer.rules.image = ImageRendererRule;
  return markdownConvertor;
}