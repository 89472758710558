import React from 'react';
import PropTypes from 'prop-types';
import MuiLink from '@material-ui/core/Link';
import { Link } from 'react-router';
import iconFactory from '../Icons/iconFactory';

function isSubRouteOf(parentRoute, childRoute) {
  return childRoute.match(`^${parentRoute}/`);
}

export default function SideNavMenuItem(props) {
  const isActive = (props.currentLocation.pathname === props.route
    || isSubRouteOf(props.route, props.currentLocation.pathname));
  const className = isActive
    ? 'hm-active-menu-item'
    : '';

  return (
    <div
      style={{ width: '100%', height: 62 }}
      className={`hm-aligner ${className}`}
    >
      <Link to={props.route} className="hm-nav-icon">
        {React.createElement(iconFactory(props.icon), { className: 'hm-side-nav-menu-icon' })}
        <span className="hm-nav-title"><MuiLink component="span" color={isActive ? 'primary' : 'inherit'} underline="none">{props.label}</MuiLink></span>
      </Link>
    </div>
  );
}

SideNavMenuItem.propTypes = {
  icon: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  label: PropTypes.string,
  currentLocation: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
};

SideNavMenuItem.defaultProps = {
  label: '',
};

SideNavMenuItem.contextTypes = {
  router: PropTypes.shape({}),
};
