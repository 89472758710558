import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '../Button';

export default class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password1: '',
      password2: '',
      password1Error: false,
      password2Error: false,
    };
  }

  componentDidMount() {
    this.props.verifyReset();
  }

  updateErrors() {
    const { password1, password2 } = this.state;

    if (password1 && password1.length < 8) {
      this.setState({ password1Error: true });
    } else {
      this.setState({ password1Error: false });
    }

    if (password1 && password2 && password1 !== password2) {
      this.setState({ password2Error: true });
    } else {
      this.setState({ password2Error: false });
    }
  }

  changePassword(event) {
    event.preventDefault();
    this.props.changePassword(this.state.password1);
  }

  render() {
    return (
      <form style={{ paddingTop: '1em' }}>
        <TextField
          label="New password"
          placeholder="New password"
          type="password"
          value={this.state.password1}
          fullWidth
          error={!!this.state.password1Error}
          helperText={
            this.state.password1Error ? 'Passwords must be 8 characters or longer' : ''
          }
          onChange={(event) => {
            this.setState({ password1: event.target.value }, () => this.updateErrors());
          }}
        />
        <div style={{ height: '1em', width: '100%' }} />
        <TextField
          label="Confirm password"
          placeholder="Confirm password"
          type="password"
          value={this.state.password2}
          fullWidth
          error={!!this.state.password2Error}
          helperText={this.state.password2Error ? 'Passwords do not match' : ''}
          onChange={(event) => {
            this.setState({ password2: event.target.value }, () => this.updateErrors());
          }}
        />
        <div style={{ height: '1em', width: '100%' }} />
        <div style={{ paddingTop: '1em' }}>
          <Button
            type="submit"
            disabled={
              this.state.password1Error ||
              this.state.password2Error ||
              this.props.isChangingPassword
            }
            fullWidth
            onClick={e => this.changePassword(e)}
          >
            Change
          </Button>
        </div>
      </form>

    );
  }
}

ChangePassword.propTypes = {
  verifyReset: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  isChangingPassword: PropTypes.bool.isRequired,
};
