import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Button from './Button';

/**
 * Renders a hivemind button wrapped in a react-router link
 * @param {string} to the route to link to
 */
export default function ButtonLink({ to, children, ...rest }) {
  return <Button
    component={React.forwardRef((props, ref) => <Link to={to} {...props} ref={ref}/>)}
    {...rest}
  >
    {children}
  </Button>;
}

ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
};

ButtonLink.defaultProps = {
  children: null,
};
