import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function Home({ iconColor }) {
  return (
    <path
      d="M11.184 1.335L1 11.62h1.517V23h6.07v-8.345h6.827V23h6.069V11.62H23L12.816 1.336A1.16 1.16 0 0 0 12.002 1h-.003c-.305 0-.598.12-.815.335z"
      stroke={iconColor}
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />);
}

Home.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(Home);
