import { connect } from 'react-redux';
import { authCallback, loginUser } from '../../../actions/Auth/authActions';
import LoginForm from './LoginForm';
import { trackLogin } from '../../../analytics/events/tracking';

function mapDispatchToProps(dispatch, ownProps) {
  return {
    login: (username, redirect, environment) => {
      dispatch(loginUser(username, ownProps.claim, redirect))
        .then(() => trackLogin(environment))
        .catch(() => {
          // Do nothing
        });
    },
    callback: (redirect, environment) => dispatch(authCallback(ownProps.location.query, ownProps.claim, redirect))
      .then(() => trackLogin(environment))
      .catch(() => {
        // Do nothing
      }),
  };
}

function mapStateToProps(state, ownProps) {
  return {
    isAuthenticating: state.auth.isAuthenticating,
    environment: state.config.environment,
    statusText: state.authReducer.loginReducer.statusText,
    redirect:
      (ownProps.location.query.redirect || localStorage.getItem('redirect'))
      ?? '/',
    subText: ownProps.subText,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
