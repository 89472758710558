import _slicedToArray from "/Users/db/Projects/agora/agora-web/node_modules/.pnpm/@babel+runtime@7.3.1/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import { useState, useEffect } from 'react';

/**
 * Returns an array of dom elements with the given className.
 * @param {ref} markdownRef Ref to element where markdown is rendered inside
 * @param {string} markdown the markdown being rendered - used to decide when to handle changes
 * @param {string} placeholderClassName the class name of the rendered elements to get handles on
 * @param {function} cleanupPlaceholder callback used to cleanup placeholders
 */
export default function usePlaceholder(markdownRef, markdown, placeholderClassName, cleanupPlaceholder) {
  var _useState = useState([]),
    _useState2 = _slicedToArray(_useState, 2),
    placeholders = _useState2[0],
    setPlaceholders = _useState2[1];

  // when the markdown changes we need to get a handle on new placeholder elements again
  useEffect(function () {
    if (markdown && markdownRef.current) {
      var domElements = markdownRef.current.getElementsByClassName(placeholderClassName);
      setPlaceholders(Array.from(domElements));
    }
  }, [markdown, placeholderClassName]);

  // clean up placeholders if required
  useEffect(function () {
    return function () {
      if (typeof cleanupPlaceholder === 'function' && placeholders) {
        placeholders.forEach(function (p) {
          return cleanupPlaceholder(p);
        });
      }
    };
  }, [placeholders]);
  return [placeholders];
}