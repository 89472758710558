var clientAnalyticsKey;
var isInternalEmail = false;
export function registerUserAnalyticsEmail(email) {
  var regex = RegExp('@hvmd.io$', 'ig');
  isInternalEmail = regex.test(email);
}
export function analyticsEnabled() {
  return window && window.analytics && !isInternalEmail;
}
export function initAnalytics(config) {
  if (config) {
    clientAnalyticsKey = config.analyticsKey ? config.analyticsKey : '';
    if (window && window.analytics && window.analytics.load) {
      window.analytics.load(clientAnalyticsKey);
    }
  }
}