import { connect } from 'react-redux';
import AppFilterMenu from './AppFilterMenu';
import { setFilterValues } from '../../actions/appFiltersActions';
function mapStateToProps(state, ownProps) {
  return {
    filterOptions: state.appFilters.filterOptions[ownProps.filterType],
    filterValue: state.appFilters.filterValues[ownProps.filterType]
  };
}
function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSelect: function onSelect(value) {
      return dispatch(setFilterValues(ownProps.filterType, value));
    }
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AppFilterMenu);