import { connect } from 'react-redux';
import ChangePasswordForm from './ChangePasswordForm';
import { verifyReset as _verifyReset, changePassword as _changePassword } from '../actions/passwordActions';
function mapStateToProps(state) {
  return {
    isChangingPassword: state.auth.isChangingPassword
  };
}
function mapDispatchToProps(dispatch, ownProps) {
  return {
    verifyReset: function verifyReset() {
      var _ownProps$location$qu = ownProps.location.query,
        email = _ownProps$location$qu.email,
        key = _ownProps$location$qu.key;
      dispatch(_verifyReset(email, key));
    },
    changePassword: function changePassword(password) {
      var _ownProps$location$qu2 = ownProps.location.query,
        email = _ownProps$location$qu2.email,
        key = _ownProps$location$qu2.key;
      dispatch(_changePassword(email, key, password));
    }
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);