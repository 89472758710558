import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function Reject({ iconColor }) {
  return (
    <g
      stroke={iconColor}
      strokeWidth="1.5"
      fill={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </g>
  );
}

Reject.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(Reject);
