import React from 'react';
import PropTypes from 'prop-types';
import Surface from '../Surface';

export default function Toolbar({ children }) {
  return (<div className="hm-toolbar" style={{ paddingBottom: '0.25em' }}>
    <Surface
      hueLevel={1}
      enableLift
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        position: 'sticky',
        top: 0,
        zIndex: 999,
        borderRadius: '0.25em',
        padding: 0,
      }}
    >
      {children}
    </Surface>
  </div>);
}

Toolbar.propTypes = {
  children: PropTypes.node.isRequired,
};
