import { VIDEO_TAG_REGEX, PLUGIN_NAME } from './VideoPluginConsts';
export default function validateToken(state, silent) {
  if (state.src.charAt(state.pos) !== '@') {
    return false;
  }
  var match = VIDEO_TAG_REGEX.exec(state.src);
  if (!match || match.length < 2) {
    return false;
  }
  var videoUrl = match[1];
  if (!silent) {
    var token = state.push(PLUGIN_NAME, '');
    token.videoUrl = videoUrl;
  }

  /* eslint-disable no-param-reassign */
  state.pos += state.src.indexOf(')', state.pos) + 1;
  state.posMax = state.tokens.length;
  state.videoUrl = videoUrl;
  /* eslint-enable no-param-reassign */
  return true;
}