import _toConsumableArray from "/Users/db/Projects/agora/agora-web/node_modules/.pnpm/@babel+runtime@7.3.1/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == typeof i ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != typeof t || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != typeof i) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { ADD_FILTER_TYPE, SET_FILTER_OPTIONS, SET_FILTER } from '../actions/appFiltersActionTypes';
function initialState() {
  return {
    filterTypes: [],
    filterValues: {},
    filterOptions: {}
  };
}
export default function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState();
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ADD_FILTER_TYPE:
      {
        var type = action.payload.type;
        var filterTypes = [].concat(_toConsumableArray(state.filterTypes.filter(function (f) {
          return f !== type;
        })), [type]);
        return _objectSpread(_objectSpread({}, state), {}, {
          filterTypes: filterTypes
        });
      }
    case SET_FILTER_OPTIONS:
      {
        var filterOptions = _objectSpread({}, state.filterOptions);
        filterOptions[action.payload.type] = action.payload.options;
        return _objectSpread(_objectSpread({}, state), {}, {
          filterOptions: filterOptions
        });
      }
    case SET_FILTER:
      {
        var filterValues = _objectSpread({}, state.filterValues);
        filterValues[action.payload.type] = action.payload.value;
        return _objectSpread(_objectSpread({}, state), {}, {
          filterValues: filterValues
        });
      }
    default:
      return state;
  }
}