import snackbarActionTypes from '@agora/agora-common/actions/snackbarActionTypes';
import {
  getProfile,
  updateProfile as callUpdateProfile,
  deleteProfile as callDeleteProfile,
  getProfilePreferences,
  updateProfilePreferences,
  getVerifyEmail,
  sendVerifyEmail as callSendVerifyEmail,
} from '../../utils/api';
import { logout, renewToken } from '../Auth/authActions';
import actionTypes from './actionTypes';
import { notifyHttpError } from '../Common/actions';

function fetching() {
  return {
    type: actionTypes.FETCH_PROFILE_REQUEST,
  };
}

function fetched(profile) {
  return {
    type: actionTypes.FETCH_PROFILE_SUCCESS,
    payload: profile,
  };
}

function saving() {
  return {
    type: actionTypes.SAVE_PROFILE_REQUEST,
  };
}

export function saved(profile) {
  return {
    type: actionTypes.SAVE_PROFILE_SUCCESS,
    payload: profile,
  };
}

export function saveFailure() {
  return {
    type: actionTypes.SAVE_PROFILE_FAILURE,
  };
}

function getProfileVerifiedSuccess(payload) {
  return {
    type: actionTypes.FETCH_PROFILEVERIFIED_SUCCESS,
    payload,
  };
}

function deleteRequest() {
  return {
    type: actionTypes.DELETE_PROFILE_REQUEST,
  };
}

function deleteSuccess() {
  return {
    type: actionTypes.DELETE_PROFILE_SUCCESS,
  };
}

export function resetSaveProfile() {
  return {
    type: actionTypes.SAVE_PROFILE_RESET,
  };
}

export function fetchEmailVerified() {
  return dispatch => getVerifyEmail()
    .then(() => {
      dispatch(getProfileVerifiedSuccess({ verified: true }));
      return Promise.resolve();
    })
    .catch(() => dispatch(getProfileVerifiedSuccess({ verified: false })));
}

export function fetchProfile() {
  return dispatch => {
    dispatch(fetching());
    return Promise.all([
      getProfile(),
      getProfilePreferences(),
      dispatch(fetchEmailVerified()),
    ]).then(values => {
      const profile = values[0];
      const prefs = values[1];
      dispatch(
        fetched({
          ...profile,
          preferences: prefs,
        }),
      );
      return values;
    });
  };
}

export function updateProfile(
  name,
  realName,
  email,
  notificationPreferences,
  socialPreferences,
) {
  return dispatch => {
    const profile = { name, realName, email };
    dispatch(saving());
    return callUpdateProfile(profile)
      .then(() => updateProfilePreferences({
        notificationPreferences,
        socialPreferences,
      }))
      .then(() => dispatch(fetchEmailVerified()))
      .then(() => dispatch(renewToken())) // Update email in the bearer token
      .then(() => dispatch(saved(profile)))
      .catch(error => {
        dispatch(saveFailure());
        dispatch(notifyHttpError(error));
      });
  };
}

export function deleteProfile(id) {
  return dispatch => {
    dispatch(deleteRequest());
    return callDeleteProfile(id)
      .then(() => dispatch(deleteSuccess()))
      .then(() => dispatch(logout()))
      .catch(error => dispatch(notifyHttpError(error)));
  };
}

export function sendVerifyEmail() {
  return dispatch => {
    callSendVerifyEmail().then(() => dispatch({
      type: snackbarActionTypes.OPEN,
      payload: {
        message: 'Verification email sent. Please check your inbox.',
        success: true,
      },
    }));
  };
}
