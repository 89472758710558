import React from 'react';
import PropTypes from 'prop-types';

export default class AuthenticatedComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null,
    };
    this.checkAuth();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isAuthenticated !== prevProps.isAuthenticated ||
      this.props.isInitialising !== prevProps.isInitialising
    ) {
      this.checkAuth();
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info });
  }

  checkAuth = () => {
    if (!this.props.isAuthenticated && !this.props.isInitialising) {
      this.props.verifyLogin();
    }
  };

  render() {
    if (this.state.hasError) {
      const error =
        this.state.error && this.state.error.message
          ? this.state.error.message
          : '';

      const componentStack =
        this.state.info && this.state.info.componentStack
          ? this.state.info.componentStack
          : '';

      return (
        <div style={{ height: '100vh' }} className="row center-xs middle-xs">
          <div>
            <h1 className="col-xs-12">Oops! Something went wrong</h1>
            <p className="col-xs-12">Please contact support.</p>
            <h2>Details</h2>
            <pre className="col-xs-12">{error}</pre>
            <pre className="col-xs-12">{componentStack}</pre>
          </div>
        </div>
      );
    }

    const Component = this.props.componentRequiringAuthentication;
    return (
      <div style={{ height: '100%' }}>
        {this.props.isAuthenticated === true ? (
          <Component {...this.props} />
        ) : null}
      </div>
    );
  }
}

AuthenticatedComponent.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isInitialising: PropTypes.bool.isRequired,
  verifyLogin: PropTypes.func.isRequired,
  componentRequiringAuthentication: PropTypes.func.isRequired,
};
