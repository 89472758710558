import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  root: {
    width: '1px',
    background: alpha(theme.palette.text.secondary, 0.35),
    height: '1.5em',
    margin: '0.25em .5em 0.25em .5em',
    display: 'inline-block',
  },
});

function Divider({ classes }) {
  return (<div className={classes.root} />);
}

export default withStyles(styles)(Divider);

Divider.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};
