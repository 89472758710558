export default function clearAnalytics() {
  if (window && window.analytics) {
    if (window.analytics.reset) {
      window.analytics.reset();
    }
    if (window.analytics.ready) {
      window.analytics.ready(function () {
        if (window.mixpanel && window.mixpanel.cookie && window.mixpanel.cookie.clear) {
          window.mixpanel.cookie.clear();
        }
      });
    }
  }
}