import 'whatwg-fetch';
import merge from 'lodash.merge';
import parse from 'parse-link-header';
import { refreshAuthIfNeeded } from './authUtils';
export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  var error = new Error(response.statusText);
  error.response = response;
  throw error;
}
function parseLinkHeaders(response) {
  return parse(response.headers.get('Link'));
}
export function isJson(response) {
  var contentType = response.headers.get('content-type');
  return response.status !== 204 && contentType && contentType.indexOf('application/json') !== -1;
}
export default function authFetch(url, opts) {
  var _ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
    _ref$returnLinkHeader = _ref.returnLinkHeaders,
    returnLinkHeaders = _ref$returnLinkHeader === void 0 ? false : _ref$returnLinkHeader,
    _ref$returnRaw = _ref.returnRaw,
    returnRaw = _ref$returnRaw === void 0 ? false : _ref$returnRaw;
  return refreshAuthIfNeeded().then(function () {
    var token = localStorage.getItem('token');
    var authOpts = {
      headers: {
        Authorization: "Bearer ".concat(token),
        'Cache-Control': 'no-cache'
      },
      credentials: 'include'
    };
    return fetch(url, merge(authOpts, opts));
  }).then(checkHttpStatus).then(function (response) {
    var payload = !returnRaw && isJson(response) ? response.json() : response.body;
    if (returnLinkHeaders) {
      return payload.then(function (p) {
        return {
          linkHeaders: parseLinkHeaders(response),
          response: p
        };
      });
    }
    return payload;
  });
}