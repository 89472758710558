import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import InitialsAvatar from './InitialsAvatar';
import ScreenReaderOnly from '../../ScreenReaderOnly';

const styles = {
  avatarButton: {
    padding: 0,
    width: 48,
    height: 48,
    label: { height: 48 },
  },
};

class AppFilterMenu extends React.Component {
  state = {
    menuAnchorElement: null,
    tooltipOpen: false,
  };

  handleClick = (event) => {
    this.setState({ menuAnchorElement: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ menuAnchorElement: null });
  };

  handleTooltipClose = () => {
    this.setState({ tooltipOpen: false });
  };

  handleTooltipOpen = () => {
    this.setState({ tooltipOpen: true });
  };

  onSelect = (value) => {
    this.props.onSelect(value);
    this.handleClose();
  }

  render() {
    const { menuAnchorElement, tooltipOpen } = this.state;
    const { filterValue, filterOptions, filterType, classes } = this.props;
    const menuOpen = !!menuAnchorElement;

    if (!filterValue) return null;

    return (
      <Tooltip
        title={`${filterType}: ${filterValue.name}`}
        placement="right"
        onClose={this.handleTooltipClose}
        onOpen={this.handleTooltipOpen}
        open={tooltipOpen && !menuOpen}
      >
        <div>
          <IconButton
            aria-label="More"
            aria-owns={menuOpen ? 'long-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
            className={classes.avatarButton}
          >
            <InitialsAvatar name={filterValue.name} />
            <ScreenReaderOnly>More menu</ScreenReaderOnly>
          </IconButton>
          <Menu
            anchorEl={menuAnchorElement}
            open={menuOpen}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                maxWidth: 250,
                width: 'auto',
                zIndex: 999,
              },
            }}
          >
            {filterOptions.map(org => (
              <MenuItem
                key={org.id}
                selected={org.id === filterValue.id}
                onClick={() => this.onSelect(org)}
              >
                <ListItemIcon>
                  <InitialsAvatar name={org.name} />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                  {org.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Tooltip>);
  }
}

export default withStyles(styles)(AppFilterMenu);

AppFilterMenu.propTypes = {
  onSelect: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, id: PropTypes.number })).isRequired,
  filterValue:
    PropTypes.shape({ name: PropTypes.string, id: PropTypes.number }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

