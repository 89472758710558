import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function FileDownload({ iconColor }) {
  return (
    <g fill={iconColor}>
      <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </g>
  );
}

FileDownload.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(FileDownload);
