import React from 'react';
import PropTypes from 'prop-types';
import blueGrey from '@material-ui/core/colors/blueGrey';
import themes from '../styles/Themes';

const darkHues = {
  0: blueGrey[900],
  1: blueGrey[800],
  2: blueGrey[700],
  3: blueGrey[600],
};

const lightHues = {
  0: blueGrey[50],
  1: blueGrey[100],
  2: blueGrey[200],
  3: blueGrey[300],
};

function getColor(theme, hue) {
  switch (theme) {
    case themes.DARK:
    case themes.DARK_BLUE: {
      return darkHues[hue];
    }
    case themes.LIGHT: {
      return lightHues[hue];
    }
    default:
      return blueGrey[600];
  }
}

export default function Surface({ children, theme, style, hueLevel, enableLift }) {
  let addStyles;
  if (enableLift) {
    addStyles = {
      boxShadow: '0 5px 35px 5px #00000030',
      border: `1px solid ${getColor(theme, 3)}`,
    };
  }
  return <div style={{
    padding: '2em',
    borderRadius: '1.5em',
    backgroundColor: getColor(theme, hueLevel),
    ...addStyles,
    ...style,
  }}
  >
    {children}
  </div>;
}

Surface.propTypes = {
  children: PropTypes.node.isRequired,
  hueLevel: PropTypes.number,
  enableLift: PropTypes.bool,
  theme: PropTypes.oneOf([themes.LIGHT, themes.DARK, themes.DARK_BLUE]).isRequired,
  style: PropTypes.shape({}),
};

Surface.defaultProps = {
  style: {},
  hueLevel: 0,
  enableLift: false,
};
