function getStyleValue(attrs, styleType) {
  if (!attrs || attrs.length < 1) {
    return null;
  }
  for (var i = 0; i < attrs.length; i += 1) {
    var att = attrs[i];
    if (att && att.length > 1) {
      if (att[0] === styleType) {
        return att[1];
      }
    }
  }
  return null;
}
function getStyleAttribute(tokenAttributes, styleType) {
  var styleValue = getStyleValue(tokenAttributes, styleType);
  return styleValue ? " style=\"".concat(styleType, ":").concat(styleValue, ";\"") : '';
}
export default function render(styleType) {
  return function (tokens, idx) {
    var token = tokens[idx];
    var isClosingTag = token.nesting === -1;
    var isOpeningTag = token.nesting === 1;
    if (!isClosingTag && !isOpeningTag) {
      return '';
    }
    return "<".concat(isClosingTag ? '/' : '', "span").concat(isOpeningTag ? getStyleAttribute(token.attrs, styleType) : '', ">");
  };
}