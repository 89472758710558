import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import MarkdownRendererPane from '../../MarkdownRendererPane';
import { LoadingPanel } from '../../Loading';
import ThemeProvider from '../../ThemeProvider';

export default function ChangelogDialogContent() {
  const [loading, setLoading] = useState(false);
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    if (!loading) {
      setLoading(true);
      fetch('https://docs.hvmd.io/public/changelog.md')
        .then(response => response.text().then(md => setMarkdown(md)))
        .catch(() => setMarkdown('# {color:red}Error loading changelog.'))
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return <div>
    <Typography variant="h1" style={{ paddingBottom: '1em' }}>Changelog</Typography>
    <LoadingPanel isLoading={loading}>
      <ThemeProvider>
        <MarkdownRendererPane markdown={markdown} />
      </ThemeProvider>
    </LoadingPanel>
  </div>;
}
