import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

export default function NestedMenuItem({ mainMenuOpen, label, children }) {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const subMenuRef = useRef(null);
  const nestedMenuRef = useRef(null);

  const handleMouseEnter = (e) => {
    e.stopPropagation();
    setSubMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setSubMenuOpen(false);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    setSubMenuOpen(!subMenuOpen);
  };

  const handleFocus = (evt) => {
    if (evt.target === nestedMenuRef.current) {
      setSubMenuOpen(true);
    }
  };

  return (
    <MenuItem
      ref={nestedMenuRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      onFocus={handleFocus}
      style={{ outline: 'none' }}
    >
      <ListItemText>
        {label}
      </ListItemText>

      <Menu
        style={{ pointerEvents: 'none' }}
        anchorEl={nestedMenuRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={subMenuOpen && mainMenuOpen}
        onClose={() => setSubMenuOpen(false)}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
      >
        <div ref={subMenuRef} style={{ pointerEvents: 'auto' }}>
          {children}
        </div>
      </Menu>
      <ListItemSecondaryAction>
        <ArrowRight />
      </ListItemSecondaryAction>
    </MenuItem>
  );
}

NestedMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  mainMenuOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

NestedMenuItem.defaultProps = {
  mainMenuOpen: true,
};
