import { shape, node, bool, func, oneOf, oneOfType, object } from 'prop-types';
import { hmButtonColorPropType } from './colorPropType';
export default shape({
  children: node,
  Icon: oneOfType([func, object]),
  size: oneOf(['small', 'medium', 'large']),
  variant: oneOf(['text', 'outlined', 'contained']),
  disabled: bool,
  fullWidth: bool,
  onClick: func,
  color: hmButtonColorPropType
}).isRequired;