import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'rc-scrollbars';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { withStyles } from '@material-ui/core';
import SideNavHeader from './SideNavHeader';
import SideNavFooterMenu from './SideNavFooterMenuContainer';
import SideNavMenuItem from './SideNavMenuItem';
import './sidenav.css';

const StyledDrawer = withStyles(theme => ({
  paper: {
    overflow: 'hidden',
    width: 76,
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default,
  },
}))(Drawer);

export default class SideNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      bottomSheetOpen: false,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this.toggleBottomSheet = this.toggleBottomSheet.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  toggleBottomSheet() {
    this.setState(previous => ({
      bottomSheetOpen: !previous.bottomSheetOpen,
    }));
  }

  updateDimensions() {
    this.setState({ height: window.innerHeight });
  }

  getFooterHeight = () => {
    const countItems = this.props.appFilterCount + (this.props.bottomSheet !== null ? 1 : 0) + 1;
    let footerHeight = (countItems) * 48;

    if (this.props.showChangelog) {
      footerHeight += 55;
    }

    return footerHeight;
  }

  render() {
    const footerHeight = this.getFooterHeight();

    return (
      <div style={{ width: 76, backgroundColor: 'grey' }}>
        <StyledDrawer
          className="hm-side-nav"
          variant="permanent"
        >
          <SideNavHeader />
          <Scrollbars
            style={{
              height: this.state.height - (84 + footerHeight),
              marginTop: 72,
            }}
            autoHide
          >
            {
              this.props.menuItems.map(mi =>
                (<SideNavMenuItem
                  key={mi.route}
                  route={mi.route}
                  icon={mi.icon}
                  label={mi.label}
                  currentLocation={this.props.currentLocation}
                />))
            }
          </Scrollbars>
          <SideNavFooterMenu
            toggleBottomSheet={this.toggleBottomSheet}
            showBottomSheetButton={this.props.bottomSheet !== null}
            bottomSheetIcon={this.props.bottomSheetIcon}
            footerHeight={footerHeight}
            showChangelog={this.props.showChangelog}
          />
        </StyledDrawer>
        <SwipeableDrawer
          onClose={this.toggleBottomSheet}
          onOpen={this.toggleBottomSheet}
          open={this.state.bottomSheetOpen}
          style={{ backgroundColor: 'rgba(51, 63, 72, 0.6)' }}
          anchor="bottom"
        >
          {
            // this is required to trigger a mount on each new opening of the panel
            this.state.bottomSheetOpen &&
            this.props.bottomSheet
          }
        </SwipeableDrawer>
      </div>
    );
  }
}

SideNav.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
  })).isRequired,
  currentLocation: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  bottomSheet: PropTypes.element,
  bottomSheetIcon: PropTypes.element,
  appFilterCount: PropTypes.number.isRequired,
  showChangelog: PropTypes.bool,
};

SideNav.defaultProps = {
  bottomSheet: null,
  bottomSheetIcon: null,
  showChangelog: false,
};
