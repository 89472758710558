import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '../../Button';

function isValidEmail(email) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email);
}

export default function SSOLoginForm({
  isAuthenticating,
  login,
}) {
  const [username, setUsername] = useState('');
  const [useRefreshToken, setUseRefreshToken] = useState(false);
  const [usernameErrorText, setUsernameErrorText] = useState('');

  const validate = () => {
    let anyErrors = false;

    if (!username || !isValidEmail(username)) {
      setUsernameErrorText('Please enter your corporate email address');
      anyErrors = true;
    }

    return !anyErrors;
  };

  return (
    <div>
      <form style={{ paddingTop: '1em' }}>
        <div>
          <TextField
            error={!!usernameErrorText}
            helperText={usernameErrorText}
            placeholder="Email"
            fullWidth
            value={username}
            onChange={event => setUsername(event.target.value)}
          />
        </div>
        <div style={{ height: '1em', width: '100%' }} />

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flexGrow: 1 }} />
          <div>
            <label>
              <span
                style={{
                  fontSize: '0.7em',
                  fontWeight: 600,
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
              >
                Keep me logged in
              </span>
              <Checkbox
                checked={useRefreshToken}
                onChange={event => setUseRefreshToken(event.target.checked)}
                style={{ padding: '0 0 0 4px' }}
                color="primary"
              />
            </label>
          </div>
        </div>

        <div style={{ paddingTop: '1em' }}>
          <Button
            type="submit"
            className="hm-button"
            disabled={!username || isAuthenticating}
            fullWidth
            onClick={(e) => {
              e.preventDefault();

              if (validate()) {
                login(username, useRefreshToken);
              }
            }}
          >
            Sign in with SSO
          </Button>
        </div>
      </form>
    </div>
  );
}

SSOLoginForm.propTypes = {
  isAuthenticating: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
};

SSOLoginForm.defaultProps = {
};
