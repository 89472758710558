import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function Remove({ iconColor }) {
  return (
    <path d="M19 13H5v-2h14v2z" fill={iconColor} />
  );
}

Remove.propTypes = {
  iconColor: PropTypes.string.isRequired,
};


export default styleIcon(Remove);
