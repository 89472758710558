import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@agora/agora-common';

const styles = (theme) => ({
  root: {
    paddingBottom: '40px',
    color: theme.palette.primary.contrastText,
    '& input': {
      color: theme.palette.primary.contrastText,
    },
  },
  content: {
    display: 'block',
  },
  title: {
    color: '#8ea0b3',
    fontFamily: 'Lato',
    fontSize: '17px',
    fontWeight: 'bold',
    paddingBottom: '15px',
  },
});

function FormSection({
  classes, title, children, hidden,
}) {
  return (
    <div
      className={classNames(classes.root)}
      style={{ display: hidden ? 'none' : 'block' }}
    >
      {title ? <div className={classNames(classes.title)}>{title}</div> : null}
      <div className={classNames(classes.content)}>
        {children}
      </div>
    </div>
  );
}

FormSection.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  hidden: PropTypes.bool,
  title: PropTypes.node,
};

FormSection.defaultProps = {
  children: null,
  hidden: false,
  title: null,
};

export default withStyles(styles)(withTheme(FormSection));
