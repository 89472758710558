import React from 'react';
import PropTypes from 'prop-types';
import styleIcon from './Hoc/styleIcon';

function Tasks({ iconColor }) {
  return (
    <g
      fill="none"
      fillRule="evenodd"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    >
      <path d="M22.62 12c0 5.866-4.754 10.62-10.62 10.62S1.38 17.867 1.38 12 6.133 1.38 12 1.38 22.62 6.133 22.62 12z" />
      <path d="M10.483 15.034H6.69V5.931h6.827v3.035" />
      <path d="M10.483 18.069h6.827V8.966h-6.827z" />
    </g>
  );
}

Tasks.propTypes = {
  iconColor: PropTypes.string.isRequired,
};

export default styleIcon(Tasks);
